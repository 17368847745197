import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DOCUMENTS } from 'constants/routeConstants';
import { GetDocument } from 'services/MyRacService';
import trackEvent from 'services/AnalyticsService';

const TermsAndConditions = () => {
  const { customerId } = useSelector((state) => state.session);
  const pageModel = useSelector((state) => state.renewal.pageModel);
  const policyInfo = useSelector((state) => state.policy.policyInfo);
  const dummyPath = ''; // Dummy filepath created to avoid linting errors
  const linkRef = useRef();
  const navigate = useNavigate();

  const getDocument = (e, data, custId) => {
    e.preventDefault();
    const doc = data?.documents?.termsConditions;
    if (doc) {
      const documentType = doc.DocumentType === 'Booklet' && doc.DocumentType.toLowerCase();
      return GetDocument(custId, documentType, doc.DocumentId)
        .then((blob) => {
          const file = new Blob([blob.payload], {
            type: 'application/pdf',
          });
          // The linkRef controls the document download
          linkRef.current.href = URL.createObjectURL(file);
          linkRef.current.download = `${doc.Name}.pdf`;
          linkRef.current.click();
        });
    }
    return false;
  };

  return (
    <span>
      By renewing, you confirm the cover is right for you, your vehicle is roadworthy, and you’ve read the&nbsp;
      {pageModel?.documents?.termsConditions?.documentLocation ? (
        <>
          <a onClick={(e) => { getDocument(e, pageModel, customerId, policyInfo); trackEvent('MyRAC_Renewal_TandCs'); }} href={dummyPath}>
            T&Cs
          </a>
          <a className="d-none" role="button" href={dummyPath} ref={linkRef}>Download Ref</a>
        </>
      ) : (
        <>
          T&Cs
        </>
      )}
      &nbsp;and <a onClick={() => { trackEvent('MyRAC_Renewal_Docs'); navigate(DOCUMENTS); }} href={dummyPath}>Renewal document</a>. Prices include applicable taxes.
    </span>
  );
};

export default TermsAndConditions;
