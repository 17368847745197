import {
  SET_RENEWAL_PAGE_MODEL,
  SET_RENEWAL_FEATURE_TAG,
  SET_RENEWAL_PAYMENT_PAYLOAD,
  SELECT_RENEWAL_UPGRADE,
  REMOVE_RENEWAL_UPGRADE,
  SELECT_RENEWAL_FLR,
  REMOVE_RENEWAL_FLR,
  SET_RENEWAL_VEHICLES,
  CHANGE_RENEWAL_VEHICLE,
  SET_RENEWAL_AUTORENEW,
  SET_RENEWAL_PAPERLESS,
  SET_CHOSEN_MONTHLY_FLEX_QUOTE,
  SET_DIRECT_DEBIT_DATA,
} from 'constants/renewalConstants';

// eslint-disable-next-line default-param-last
const renewalReducer = (state = {
  pageModel: {},
  featureTag: '',
  paymentPayload: {
    CustomerId: '',
    MembershipNumber: '',
    Price: 0,
    MonthlyLongerTermPolicy: false,
    RenewalRiskVersion: '',
    StateToken: '',
    PolicyNumber: '',
    ConfirmationPage: {},
    AdobeOfferStatus: null,
    PropositionId: null,
    AdobeCode: null,
    AdobeOfferTitle: null,
    PaymentDate: null,
    DirectDebitPage: null,
    EnableAutoRenew: null,
    EnablePaperless: null,
    Amendments: null,
    PriceBeforeDiscount: 0,
    MemberChoseSwitchToMonthlyFlex: false,
  },
  basket: {
    upgrades: [],
    freeLoyaltyReward: {
      title: '',
      adobeCode: '',
      propositionId: '',
      productShortDescription: '',
      productLongDescription: '',
    },
    vehicles: [],
    toggles: {
      autoRenew: false,
      paperless: true,
    },
    chosenMonthlyFlex: false,
    directDebitData: null,
  },
}, action) => {
  switch (action.type) {
    case SET_RENEWAL_PAGE_MODEL:
      return {
        ...state,
        pageModel: {
          ...action.payload,
        },
      };
    case SET_RENEWAL_FEATURE_TAG:
      return {
        ...state,
        featureTag: action.payload,
      };
    case SET_RENEWAL_PAYMENT_PAYLOAD:
      return {
        ...state,
        paymentPayload: {
          ...action.payload,
        },
      };
    case SELECT_RENEWAL_UPGRADE:
      // Upgrade already selected:
      if (state.basket.upgrades.filter((upgrade) => action.payload.productCode === upgrade.productCode).length > 0) {
        return state;
      }
      return {
        ...state,
        basket: {
          ...state.basket,
          upgrades: [
            ...state.basket.upgrades,
            action.payload,
          ],
        },
      };
    case REMOVE_RENEWAL_UPGRADE:
      // Upgrade was not selected:
      if (state.basket.upgrades.filter((upgrade) => action.payload.productCode === upgrade.productCode).length === 0) {
        return state;
      }
      return {
        ...state,
        basket: {
          ...state.basket,
          upgrades: state.basket.upgrades.filter((upgrade) => action.payload.productCode !== upgrade.productCode),
        },
      };
    case SELECT_RENEWAL_FLR:
      return {
        ...state,
        basket: {
          ...state.basket,
          freeLoyaltyReward: action.payload,
        },
      };
    case REMOVE_RENEWAL_FLR:
      return {
        ...state,
        basket: {
          ...state.basket,
          freeLoyaltyReward: {
            title: '',
            adobeCode: '',
            propositionId: '',
            productShortDescription: '',
            productLongDescription: '',
          },
        },
      };
    case SET_RENEWAL_VEHICLES:
      return {
        ...state,
        basket: {
          ...state.basket,
          vehicles: action.payload,
        },
      };
    case CHANGE_RENEWAL_VEHICLE:
      return {
        ...state,
        basket: {
          ...state.basket,
          vehicles: state.basket.vehicles.map((vehicle) => {
            if (action.payload.length > 0) {
              let updatedVehicle = vehicle;

              action.payload.forEach((newVehicle) => {
                if (vehicle.Registration === newVehicle.OldReg) {
                  updatedVehicle = newVehicle;
                }
              });

              return updatedVehicle;
            }
            return state.basket.vehicles;
          }),
        },
      };
    case SET_RENEWAL_AUTORENEW:
      return {
        ...state,
        basket: {
          ...state.basket,
          toggles: {
            ...state.basket.toggles,
            autoRenew: action.payload,
          },
        },
      };
    case SET_RENEWAL_PAPERLESS:
      return {
        ...state,
        basket: {
          ...state.basket,
          toggles: {
            ...state.basket.toggles,
            paperless: action.payload,
          },
        },
      };
    case SET_CHOSEN_MONTHLY_FLEX_QUOTE:
      return {
        ...state,
        basket: {
          ...state.basket,
          chosenMonthlyFlex: action.payload,
        },
      };
    case SET_DIRECT_DEBIT_DATA:
      return {
        ...state,
        basket: {
          ...state.basket,
          directDebitData: action.payload,
        },
      };
    default:
      return state;
  }
};

export default renewalReducer;
