import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeRenewalVehicle } from 'actions/renewalActions';
import PopupModal from 'components/PopupModal/PopupModal';
import VehicleForm from 'components/VehicleForm/VehicleForm';
import TextButton from 'shared/components/TextButton/TextButton';
import InfoBlock from 'shared/components/InfoBlock/InfoBlock';
import trackEvent from 'services/AnalyticsService';

const Vehicles = () => {
  const dispatch = useDispatch();
  const pageModel = useSelector((state) => state.renewal.pageModel);
  const featureTag = useSelector((state) => state.renewal.basket.featureTag);
  const vehicles = useSelector((state) => state.renewal.basket.vehicles);

  const [isEdit, setEdit] = useState(false);
  const [vehicleForm, setVehicleForm] = useState(false);

  // Display vehicle list with edit options
  const showVehicles = (list) => list.map((vehicle, index) => vehicle && (
    <div className="row mx-5 py-1" key={vehicle?.Registration}>
      <div className="col-10 px-2" aria-hidden="true">
        {vehicles && vehicles[index]?.edit
          ? <span><b>{vehicles[index]?.Registration}</b> - {vehicles[index]?.Make} {vehicles[index]?.Model}</span>
          : <span><b>{vehicle?.Registration}</b> - {vehicle?.Make} {vehicle?.Model}</span>}
      </div>
      {pageModel?.isVehicleEditAllowed && (
        <div className="col-2">
          <TextButton buttonText="Change" onClickHandler={() => setVehicleForm({ show: true, index })} orange noUnderline />
        </div>
      )}
    </div>
  ));

  // Edit vehicle form inside modal
  const editVehicle = () => (
    <PopupModal
      resultTitle={vehicles && !isEdit ? 'Vehicle changed' : 'Change vehicle'}
      form={(
        <VehicleForm
          isEdit={setEdit}
          onAddVehicle={(updatedVehicles) => dispatch(changeRenewalVehicle(updatedVehicles))}
          policyVehicles={pageModel?.vehicles}
          newVehicles={[...vehicles]}
          analyticsTag={featureTag}
          index={vehicleForm.index}
          isModal
        />
      )}
      action={() => setVehicleForm((prev) => ({ ...prev, show: false }))}
      modalOk={vehicles && !isEdit ? 'Close' : false}
      innerCloseButton
    />
  );

  useEffect(() => {
    // Track close action of vehicle modal form
    if (vehicleForm && !vehicleForm.show) {
      trackEvent(`${featureTag}_Vehicle_Close`);
    }
  }, [vehicleForm]);

  return (
    <>
      {!!pageModel?.vehicles && (showVehicles(pageModel?.vehicles))}
      {vehicleForm.show && editVehicle()}
      {vehicles && vehicles?.find((key) => key.edit) && (
        <div className="mx-5 py-1">
          <InfoBlock fields={{ heading: 'Updating vehicles', infoHtml: <p>Changes will take effect on the renewal date. If you need it covered today, please call <b>0330 159 1111</b></p> }} />
        </div>
      )}
      <div className="border border-top opacity-50" />
    </>
  );
};

export default Vehicles;
