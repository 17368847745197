import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import { GetRenewalPaymentIdAndLockToken } from '../../services/MyRacService';
import { RENEWAL, RENEWAL_ERROR } from '../../constants/routeConstants';
import DirectDebitForm from './Form/DirectDebitForm';
import ddLogo from '../../images/paymentPage/directdebit.png';
import styles from './DirectDebitScreen.module.scss';
import TextButton from '../../shared/components/TextButton/TextButton';
import backIcon from '../../images/icons/caret-left-black.svg';
import Spinner from '../Spinner';
import PopupModal from '../PopupModal/PopupModal';
import TimeoutWarningModal from '../TimeoutWarningModal';
import trackEvent from '../../services/AnalyticsService';
import InfoBox from './InfoBox';

const DirectDebitScreen = (props) => {
  const { route, renewalPaymentPayload } = props;
  const [policyLockToken, setPolicyLockToken] = useOutletContext();
  const pageModel = useSelector((state) => state.renewal.pageModel);

  const [loading, setLoading] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModal, setErrorModal] = useState({});
  const [reloadRenewal, setReloadRenewal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [isTermsModal, setTermsModal] = useState(false);

  const navigate = useNavigate();

  // Adobe tag switch
  const featureTag = pageModel.isMonthlyLongerTermPolicy ? 'MyRAC_Renewal_MLTP' : 'MyRAC_Renewal';

  useEffect(() => {
    if (!renewalPaymentPayload) {
      // The route value is passed through MyRAC.jsx and we can use this to path future payment journeys
      if (route === RENEWAL) {
        // Redirect user to error page for this route
        navigate(RENEWAL_ERROR, { state: { isPaymentError: false, ...renewalPaymentPayload }, replace: true });
      }
    } else {
      trackEvent(`${featureTag}_DirectDebit`);
      // MYR-14728
      trackEvent('MyRAC_Renewal_Dd_Viewed');
    }
  }, []);

  const onBackClick = async () => {
    if (route === RENEWAL) {
      trackEvent(`${featureTag}_DirectDebit_Back`);
      navigate(RENEWAL, { state: { reloadRenewal } });
    }
  };

  const onModalClose = () => {
    setShowInfoModal(false);
    setTermsModal(false);
  };

  const onModalClick = () => {
    if (route === RENEWAL) {
      navigate(RENEWAL, { state: { reloadRenewal } });
    }
  };

  const displayTermsModal = () => {
    setShowInfoModal(true);
    // Boolean flag used to switch between content in modal
    setTermsModal(true);
  };

  const fetchPaymentId = async () => {
    try {
      const response = await GetRenewalPaymentIdAndLockToken();
      // Payload determines eligibility
      if (response?.payload) {
        const { lockToken, displayErrorMessage, errorMessage, errorTitle, errorReloadRisk } = response.payload;

        if (displayErrorMessage) {
          setErrorModal({ message: errorMessage, title: errorTitle });
          setReloadRenewal(errorReloadRisk);
          if (errorReloadRisk) {
            // true
            trackEvent('MyRAC_Renewal_PaymentError_PolicyUpdated');
          } else {
            // false
            trackEvent('MyRAC_Renewal_PaymentError_PolicyLocked');
          }
          setShowErrorModal(true);
          setLoading(false);
          return;
        }

        setPolicyLockToken(lockToken);
      } else {
        // Redirect to error page as not eligibile
        navigate(RENEWAL_ERROR, { state: { isPaymentError: false }, replace: true });
      }
      setLoading(false);
    } catch (e) {
      navigate(RENEWAL_ERROR, { state: { isPaymentError: false }, replace: true });
    }
  };

  useEffect(() => {
    fetchPaymentId();
  }, []);

  if (loading) {
    return <Spinner bootSpinner />;
  }
  return (
    <div className="container din-font">
      <div className={styles.nav}>
        <TextButton buttonText="Back" onClickHandler={onBackClick} iconImage={backIcon} iconImageAltText="Back" noUnderline />
      </div>
      <div className={styles.header}>
        <h2 className={styles.title}>Set up your direct debit</h2>
        {pageModel.isMonthlyLongerTermPolicy
          ? 'Please enter the bank details you want to use for monthly payments and check the payment summary'
          : ''}
      </div>
      {pageModel.isMonthlyLongerTermPolicy ? (
        // DirectDebitForm compact version
        <div className={styles.compactVersionContainer}>
          <img src={ddLogo} alt="Direct Debit" />
          <h3>Your bank details</h3>
          <DirectDebitForm
            payload={renewalPaymentPayload}
            onError={setShowErrorModal}
            onInvalid={setShowInfoModal}
            renewalToken={policyLockToken}
            displayTermsModal={displayTermsModal}
            useCompactVersion
          />
        </div>
      ) : (
        <div className={styles.container}>
          <InfoBox
            data={pageModel}
            onClick={displayTermsModal}
            image={<img src={ddLogo} alt="Direct Debit" />}
            displayCollectionInfo
          />
          <div className={styles.payframe}>
            <DirectDebitForm
              payload={renewalPaymentPayload}
              onError={setShowErrorModal}
              onInvalid={setShowInfoModal}
              renewalToken={policyLockToken}
            />
          </div>
        </div>
      )}
      <div className={styles.clear} />
      {showErrorModal
        && (
          <PopupModal
            innerCloseButton
            modalOk="Ok"
            action={onModalClick}
            resultTitle={errorModal.title}
            resultText={errorModal.message}
            displayDialogInMiddle
          />
        )}
      {showInfoModal
        && (
          <PopupModal
            innerCloseButton
            modalOk="Ok"
            action={onModalClose}
            resultTitle={isTermsModal ? renewalPaymentPayload?.DirectDebitPage?.directDebitGuaranteeModel?.header : renewalPaymentPayload?.DirectDebitPage?.errorTitle}
            resultText={isTermsModal ? ReactHtmlParser(renewalPaymentPayload?.DirectDebitPage?.directDebitGuaranteeModel?.body) : renewalPaymentPayload?.DirectDebitPage?.errorBody}
            displayDialogInMiddle
          />
        )}
      <TimeoutWarningModal policyLockToken={policyLockToken} />
    </div>
  );
};

// Props
const mapStateToProps = (state) => ({
  customer: state.customer,
  policy: state.policy,
  renewalPaymentPayload: state.renewal.paymentPayload,
  featureFlags: state.featureToggle,
});

export default connect(mapStateToProps)(DirectDebitScreen);
