import { intervalToDuration } from 'date-fns';
import format from 'date-fns/format';

export const formatDate = (date, formatString) => {
  if (!date || !Date.parse(date)) {
    return '';
  }

  return format(new Date(date), formatString || 'dd/MM/yyyy');
};

const memberSinceDateDisplay = (memberSince) => {
  if (!memberSince) {
    return 'No date provided';
  }

  const dateFrom = new Date(memberSince);
  const dateNow = new Date();
  const isDateFromValid = dateFrom instanceof Date && !Number.isNaN(dateFrom.valueOf());

  if (!isDateFromValid) {
    return 'Invalid date';
  }

  const diffDuration = intervalToDuration({
    start: dateNow,
    end: dateFrom,
  });

  const monthPlural = (`${diffDuration.months > 1 ? 'months' : 'month'}`);
  const yearPlural = (`${diffDuration.years > 1 ? 'years' : 'year'}`);

  // if less than 1 month show 1 month, else a month is a month
  if (diffDuration.years < 1) {
    return diffDuration.months <= 1 ? '1 month' : (`${diffDuration.months} ${monthPlural}`);
  }

  // logic if duration is a year or under 2 years
  if (diffDuration.years === 1) {
    return diffDuration.months < 1 ? (`${diffDuration.years} ${yearPlural}`) : (`${diffDuration.months} ${monthPlural}`);
  }

  // Greater than a year duration but months duration less than a month
  if (diffDuration.years > 1 && diffDuration.months === 0) {
    return `${diffDuration.years} ${yearPlural}`;
  }

  // Greater than a year duration and at 1 month/ over a month
  if (diffDuration.years > 1 && diffDuration.months >= 1) {
    return (`${diffDuration.years} ${yearPlural} ${diffDuration.months} ${monthPlural}`);
  }

  return diffDuration;
};

export default memberSinceDateDisplay;
