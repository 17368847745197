import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import passwordValidation from 'utils/passwordUtils/passwordValidation';
import { loggerFunc } from '../../utils/logger';
import GigyaClient from '../../auth/GigyaClient';
import * as RouteConstants from '../../constants/routeConstants';
import { PRIVATE_ROUTE_LIST } from '../../constants/routeConstants';
import { LOGIN_METHOD } from '../../constants/sessionOptionsConstants';
import { GetContent } from '../../services/ContentService';
import { ResponseCode } from '../../services/models/httpResponse';
import * as DefaultText from '../../configuration/defaultText';
import { componentMappingService, LANDING_BANNER_COMPONENT, VERIFY_USER_DETAILS_COMPONENT } from '../../services/ComponentMappingService';
import { getMessage, populateMessages } from '../../services/MessageService';
import { registrationErrors } from '../../constants/cms/messageConstants';
import trackEvent from '../../services/AnalyticsService';
import { ValidateEmail } from '../../utils/utils_validation';
import { GetCustomerData, GetCustomerDataByMemberNo, GetJWT, UpdateRegistrationEmailAddress, ValidateMember, IsEmailAvailable } from '../../services/MyRacService';
import { hasValidToken } from '../../auth/userManager';
import tokenStatus from '../../constants/tokenStatus';
import * as commons from '../../utils/commons';
import { useUserJourney } from '../../hooks/userJourney-Context';
import ComponentView from '../../common/ComponentView';
import * as SessionActions from '../../actions/sessionActions';
import { useApplicationState } from '../../hooks/applicationState-Context';
import Spinner from '../../components/Spinner';
import supportedVerificationRoutes from './helpers.ts';

const RegistrationLogin = (props) => {
  const {
    setSessionInfo, setJwtToken, children, isLoggedIn, setOneTimeEmailLoginDisabled, setLoginMethodUsed,
  } = props;

  const [pageModel, setPageModel] = useState();
  const [magicLink, setMagicLink] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [errorHeaderText, setErrorHeaderText] = useState('');
  const { appState, setPageLoading } = useApplicationState();
  const [customerDataRequested, setCustomerDataRequested] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  // cannot be done in state, as linked to gigya screen which is not loaded by us.
  let missingDataGigyaScreenDisplayed = false;

  const [searchParams] = useSearchParams();

  const { userJourneyState } = useUserJourney();

  const gigyaClient = new GigyaClient();

  const logger = loggerFunc('RegistrationLogin');

  // Deep linking to urls that require a user to be logged in
  const deepLinkAccessRoute = () => {
    // Get base url like found in routeConstants.js
    const lastPageVisited = userJourneyState.pageHistory[userJourneyState.pageHistory.length - 2];
    // Create new path to include any query string parameters
    const urlPath = location.search ? lastPageVisited + location.search : lastPageVisited;
    // Check base url is in list and redirect to new path with or without query strings
    if (PRIVATE_ROUTE_LIST.includes(lastPageVisited)) {
      return urlPath;
    }
    // Default route
    return RouteConstants.ROOT;
  };

  useEffect(() => {
    if (isLoggedIn && !appState.loading.status) {
      navigate(deepLinkAccessRoute());
      // if magic link then force the navigation to ensure menu loads correctly.
      if (magicLink) {
        setMagicLink(false);
        navigate(0);
      }
    }
  }, [isLoggedIn, appState.loading.status]); // eslint-disable-line

  const clearStateErrors = () => {
    setErrorText(undefined);
    setErrorHeaderText(undefined);
  };

  /**
   * Function that produces error messages object based on initially entered error message value.
   * @param    {String} errorMessage  A string representing the current error message.
   * @return   {{errorText: String, errorHeaderText: String}} Error text and header values.
   */
  const getRegistratonValidationError = (errorMessage) => {
    let validationErrorText;
    const validationErrorHeaderText = undefined;

    switch (errorMessage) {
      case 'Invalid Membership Number':
        validationErrorText = getMessage(registrationErrors.INVALID_MEMBERSHIP_NUMBER);
        break;
      case 'Incorrect Details':
        validationErrorText = getMessage(registrationErrors.INCORRECT_DETAILS);
        break;
      case 'Invalid Policy':
      case 'Code Excluded':
        validationErrorText = getMessage(registrationErrors.CODE_EXCLUDED);
        break;
      case 'Emails Mismatch':
        validationErrorText = getMessage(registrationErrors.EMAIL_AND_DETAILS_MISMATCH);
        break;
      case 'Details Mismatch':
        validationErrorText = getMessage(registrationErrors.DETAILS_MISMATCH);
        break;
      case 'Not Eligible For Registration':
        validationErrorText = getMessage(registrationErrors.NOT_ELIGIBLE_FOR_REGISTRATION);
        break;
      default:
        validationErrorText = getMessage(registrationErrors.REGISTRATION_DEFAULT_MESSAGE);
        break;
    }

    return {
      errorText: validationErrorText,
      errorHeaderText: validationErrorHeaderText,
    };
  };

  /**
   * Recursive function that displays a dialog box prompting the user to enter their new email address. Step 2 of user questions
   * for new email entry.
   * @return   {boolean} True if user email address confirmed and valid.
   */
  const windowUserEnterNewEmailAddress = (formEmail) => {
    // eslint-disable-next-line no-alert
    const newEmail = window.prompt('Please re-enter your new email address. Your existing marketing preferences will apply.');
    if (newEmail !== null) {
      if (newEmail === formEmail) {
        if (!ValidateEmail(newEmail)) {
          // eslint-disable-next-line no-alert
          alert('Email address is not in right format please check and try again.');
          return windowUserEnterNewEmailAddress(formEmail);
        }
        trackEvent('MyRAC_BreakdownRegisterEmailChangeConfirm');
        return newEmail;
      }
      // eslint-disable-next-line no-alert
      alert('Email address does not match email entered in registration form.');
      return windowUserEnterNewEmailAddress(formEmail);
    }
    return false;
  };

  /**
   * Function that displays a dialog box to user and waits for confirmation or cancellation. Step 1 of user questions
   * for new email entry. Progresses to Step 2 {windowUserEnterNewEmailAddress} if user wants to change their email address.
   * @return   {boolean} True if user email changed.
   */
  // Window popup prompting user for email confirmation.
  const windowDoesUserWantToChangeEmailAddress = (formEmail) => {
    // eslint-disable-next-line no-alert
    const confirmResult = window.confirm('This isn’t the email we have currently do you want to change it?');
    if (confirmResult) {
      return windowUserEnterNewEmailAddress(formEmail);
    }
    // eslint-disable-next-line no-alert
    alert('Please enter the original email you signed up with.');
    return false;
  };

  const handleError = (e, text, headerText) => {
    setErrorText(text);
    setErrorHeaderText(headerText);
    setPageLoading(false);
    logger.error(text, e);
  };

  /**
   * Function that will be called before Gigya Screen-set form is submitted.
   * Used in gigya.accounts.showScreenSet.
   * https://help.sap.com/viewer/8b8d6fffe113457094a17701f63e3d6a/GIGYA/en-US/413a5b7170b21014bbc5a10ce4041860.html
   * @param    {Object} eventObj  An event object. Expecting registration formData to be attached.
   * @return   {boolean}
   */
  const onBeforeSubmitCallback = (eventObj) => {
    if (eventObj.screen === 'gigya-passwordless-login-screen') {
      const emailRequest = {
        email: eventObj.formData.identifier,
      };

      try {
        const resultEmailAvailable = JSON.parse(IsEmailAvailable(emailRequest));
        if (resultEmailAvailable.payload) {
          if (resultEmailAvailable.payload.isEmailAvailable) {
            trackEvent('MyRAC_MagicLinkAttemptNotRegistered');
            handleError(new Error('Invalid Member'), 'You are currently not registered for myRAC.  Please close this window and click "Not Registered" to create your account.', 'Not Registered');
            return false;
          }
        }
      } catch (e) {
        handleError(e, DefaultText.loginErrorMessage, DefaultText.loginErrorTitle);
        return false;
      }
    }

    if (eventObj.screen === 'gigya-register-screen') {
      trackEvent('myrac.registration');
      const suppliedFormData = {
        firstName: eventObj.formData['profile.firstName'],
        lastName: eventObj.formData['profile.lastName'],
        postCode: eventObj.formData['profile.zip'],
        emailAddress: eventObj.formData.email,
        membershipNumber: eventObj.formData['data.memberNo'],
      };
      try {
        const result = JSON.parse(ValidateMember(suppliedFormData.membershipNumber, suppliedFormData));
        if (result.payload) {
          if (!result.payload.isValid) {
            const err = getRegistratonValidationError(result.payload.errorReason);
            handleError(new Error('Invalid member details'), err.errorText, err.errorHeaderText);
            return false;
          }
          if (result.payload.isSameEmail) {
            clearStateErrors();
            return true;
          }
          const newEmail = windowDoesUserWantToChangeEmailAddress(eventObj.formData.email);
          if (newEmail) {
            try {
              UpdateRegistrationEmailAddress(newEmail, result.payload.customerId);
              trackEvent('MyRAC_BreakdownRegisterEmailProvidedConfirm');
              clearStateErrors();
              return true;
            } catch (e) {
              handleError(e, DefaultText.loginErrorMessage, DefaultText.loginErrorTitle);
              return false;
            }
          }
          return false;
        }
        handleError(new Error('No payload found'), DefaultText.loginErrorMessage, DefaultText.loginErrorTitle);
        return false;
      } catch (e) {
        handleError(e, DefaultText.loginErrorMessage, DefaultText.loginErrorTitle);
        return false;
      }
    }
    return true;
  };

  /**
   * Function that checks the URL query parameter for a tescos 'referrer' value.
   * @return   {{ tescoLogin: boolean, tescoAffinity: boolean }} Tesco login results object.
   */
  const checkUserLoginViaTesco = () => {
    const referrers = ['tesco', 'tesco-breakdown-cover', 'tesco-renewal'];
    const referrer = searchParams.get('referrer');
    let tescoLogin = false;
    let tescoAffinity = false;
    if (referrer) {
      tescoLogin = true;
      tescoAffinity = referrers.includes(referrer);
    }
    return {
      tescoLogin,
      tescoAffinity,
    };
  };

  const login = (eventObj, uidFromResponse, trackevent) => {
    setPageLoading(true);
    const tescosLoginCheck = checkUserLoginViaTesco();

    let { clientId } = eventObj.data;

    if (!clientId) {
      GetCustomerDataByMemberNo(eventObj.data.memberNo, uidFromResponse)
        .then(async (response) => {
          clientId = response.Id;
          trackEvent(trackevent);
        })
        .catch((e) => handleError(e, getMessage()));
    }

    GetJWT(process.env.REACT_APP_SESSION_TIME_IN_SECONDS).then(async (jwtToken) => {
      setSessionInfo({
        uid: uidFromResponse,
        memberNo: eventObj.data.memberNo,
        customerId: clientId,
        isMigratedUser: !!eventObj.data.customerID,
        jwtToken,
        sessionId: commons.generateUID(8),
        isLoggedIn: true,
        loginId: null,
        isTescoLogin: tescosLoginCheck.tescoLogin,
        tescoAffinity: tescosLoginCheck.tescoAffinity,
        efulFilmentShown: false,
        bannerHidden: { covidBanner: false, unsupportedBrowserBanner: false },
        upgradedProduct: null,
        discountRenewal: false,
        oneTimeEmailDisabled: false,
      });
      await GetCustomerData()
        .then(() => {
          trackEvent(trackevent);
        })
        .catch((e) => handleError(e, getMessage()));
      setPageLoading(false);
    });
    return null;
  };

  const displayLiteLoginError = () => {
    setLoginError({
      errorTitle: 'Thanks for logging in',
      errorBody: 'Your account has been fully authenticated. To continue you’ll need to log in to the MyRAC Mobile App.',
      closeButtonAction: () => {
        setLoginError({ loginError: undefined });
        navigate(RouteConstants.LOGIN);
      },
    });
  };

  const fromMobileAppRedirect = () => {
    const intcid = searchParams.get('intcid');
    return (intcid && intcid.toLowerCase().includes('myrac-app') && intcid.toLowerCase().includes('magiclink'));
  };

  const oneTimeEmailCallBack = (eventObj) => {
    const fromVerifiedScreen = searchParams.get('verified');
    if (fromVerifiedScreen) {
      trackEvent('MyRAC_OneTimeLoginAttemptVerifiedPreLogin');
    }
    if (eventObj.errorCode !== 0) {
      let displayError = true;
      let errorMessage = `The link may of expired and it must be opened on the same device and browser from which you submitted your email address.  Error code - ${eventObj.errorCode}`;
      let trackCode = 'MyRAC_OneTimeLoginAttemptFailed';

      if (fromVerifiedScreen) {
        trackCode += 'VerifiedUser';
      }

      if (fromMobileAppRedirect()) {
        trackCode += 'MobileUser';
        // mobile user, we do not want to display the error
        // they will just go to the login page as before.
        displayError = false;
      }

      if (missingDataGigyaScreenDisplayed) {
        errorMessage = "Problem after trying to verify some of your mandatory information.  Please request a new 'One Time Email' to try again, or contact our call centre";
        trackCode = 'MyRAC_OneTimeLoginAttemptFailedMissingData';
      }

      trackEvent(trackCode);
      setPageLoading(false);
      if (displayError) {
        setLoginError({
          errorTitle: 'Oops, something went wrong',
          errorBody: errorMessage,
          closeButtonAction: () => {
            setLoginError({ loginError: undefined });
            navigate(RouteConstants.LOGIN);
          },
        });
      } else {
        navigate(RouteConstants.LOGIN);
      }
      return;
    }
    if (missingDataGigyaScreenDisplayed) {
      trackEvent('MyRAC_OneTimeLoginAttemptSuccessMissingData');
    }

    let loginTrackCode = 'myrac.loggedinonetimeemail';
    if (fromVerifiedScreen) {
      loginTrackCode = 'myrac.loggedinonetimeemailverifieduser';
    } else if (fromMobileAppRedirect()) {
      loginTrackCode += 'mobileuser';
    }

    if (eventObj.data?.memberNo === '00000') {
      trackEvent('MyRAC_OneTimeLoginAttemptFailedLiteUser');
      displayLiteLoginError();
      return;
    }
    setMagicLink(true);
    setLoginMethodUsed(LOGIN_METHOD.MAGIC_LINK);
    login(eventObj, eventObj.UID, loginTrackCode);
  };

  const magicLinkCallBack = () => {
    // check to see if the magic link has stored data in session
    gigyaClient?.getAccountInfo(oneTimeEmailCallBack);
  };

  const checkIfOneTimeEmailLogin = () => {
    const token = searchParams.get('gig_i_vToken');
    const code = searchParams.get('gig_i_code');
    // only attempt to get the user if the magiclink querystring params are passed in.
    if (token && code) {
      setPageLoading(true);
      const verified = searchParams.get('verified');
      if (verified) {
        // if coming from the verification screen, it will be done via a navigate.
        // this does not kick off the sdk magic link call automatically
        // so fire it off instead.
        gigyaClient?.loginMagicLink(token, code, magicLinkCallBack);
        return;
      }
      // if coming from a direct link from email
      // the sdk will have called the magic link login call
      // we then need to check if the account is in the session.
      gigyaClient?.getAccountInfo(oneTimeEmailCallBack);
    }
  };

  /**
   * Function that will be called after Gigya Screen-set form is submitted.
   * Used in gigya.accounts.showScreenSet.
   * https://help.sap.com/viewer/8b8d6fffe113457094a17701f63e3d6a/GIGYA/en-US/413a5b7170b21014bbc5a10ce4041860.html
   * @param    {Object} eventObj  An event object. Expecting registration formData to be attached.
   */
  const onAfterSubmitCallback = (eventObj) => {
    // For lite customers with Bodged data.
    const formDataForLite = (eventObj.formData?.['data.memberNo'] === '00000') || (eventObj.formData && Object.keys(eventObj.formData).length > 0 && !eventObj.formData?.['data.memberNo']);
    if ((eventObj.data && Object.keys(eventObj.data).length > 0 && !eventObj.data?.memberNo) || (eventObj.data?.memberNo === '00000') || formDataForLite) {
      if (eventObj.response.isVerified && !eventObj.response.emails.unverified.length) {
        trackEvent('MyRAC_BreakdownRegisterLiteUserErrorMessage');
        displayLiteLoginError();
      }
      return null;
    }

    // Invalid user error on forgot password form
    if (eventObj.screen === 'gigya-forgot-password-screen') {
      if (eventObj.response.errorMessage === 'loginID does not exist' || eventObj.response.errorMessage === 'Email not verified') {
        // Track user warning if shown after submit
        trackEvent('MyRAC_ForgotEmailError');
      }
    }

    if (eventObj.screen === 'gigya-login-screen' || eventObj.screen === 'gigya-email-code-auth-method-screen' || eventObj.screen === 'gigya-mobile-verification-screen' || eventObj.screen === 'gigya-password-auth-method-screen') {
      // Track login whether successful or not
      trackEvent('MyRAC_BreakdownLoginClick');
      if (eventObj.response.errorCode !== 0) {
        // user has not successfully validated... stop here
        setPageLoading(false);
        return null;
      }

      let method;
      if (eventObj.screen === 'gigya-mobile-verification-screen') {
        method = LOGIN_METHOD.MOBILE_VERIFICATION;
      } else if (eventObj.screen === 'gigya-email-code-auth-method-screen') {
        method = LOGIN_METHOD.EMAIL_CODE;
      } else {
        method = LOGIN_METHOD.PASSWORD; // 'gigya-login-screen' && 'gigya-password-auth-method-screen'
      }

      setLoginMethodUsed(method);
      login(eventObj, eventObj.response.UID, 'myrac.loggedin');
    }
    return null;
  };

  /**
   * Function that will be called before a new screen is rendered by Gigya. This event gives us the opportunity to set App Insight event trackers.
   * Used in gigya.accounts.showScreenSet.
   * https://help.sap.com/viewer/8b8d6fffe113457094a17701f63e3d6a/GIGYA/en-US/413a5b7170b21014bbc5a10ce4041860.html
   * @param    {Object} eventObj  An event object. Expecting the value of the current displayed Gigya screen.
   */
  const onAfterScreenLoadCallback = (eventObj) => {
    if (eventObj.currentScreen === 'gigya-magic-link-auth-method-screen') {
      document.getElementById('sentEmailAddress').innerHTML = eventObj.response.requestParams.identifier;
      trackEvent('MyRAC_OneTimeLoginEmailSent');
    }

    if (eventObj.currentScreen === 'gigya-login-screen') {
      const registerButton = document.querySelectorAll('.gigya-register-here-link');
      if (registerButton && registerButton.length) {
        registerButton.forEach((element) => {
          element.addEventListener('click', () => {
            // Track first register button
            trackEvent('MyRAC_BreakdownRegisterClick');
          });
        });
      }
    }
    if (eventObj.currentScreen === 'gigya-register-screen') {
      const registerNowButton = document.querySelector('.gigya-input-submit');
      if (registerNowButton) {
        registerNowButton.addEventListener('click', () => {
          // Track register now button
          trackEvent('MyRAC_BreakdownRegisterNowClick');
        });
      }
    }
    if (eventObj.currentScreen === 'gigya-verification-sent-screen') {
      const registerSuccessButton = document.querySelector('.gigya-button');
      if (registerSuccessButton) {
        registerSuccessButton.addEventListener('click', () => {
          // Track OK button (register complete)
          trackEvent('MyRAC_BreakdownRegisterComplete');
        });
      }
    }
    if (eventObj.currentScreen === 'gigya-forgot-password-screen') {
      // Track 'Forgot your password?' link
      trackEvent('MyRAC_ForgotPasswordClick');
      const backtoLoginLink = document.querySelector('a.forgotten.back');
      if (backtoLoginLink) {
        backtoLoginLink.addEventListener('click', () => {
          // Track 'Back to login?' link
          trackEvent('MyRAC_BackToLoginClick');
        });
      }
    }
    if (eventObj.currentScreen === 'gigya-forgot-password-success-screen') {
      // Track reset password success
      trackEvent('MyRAC_ForgotEmailSuccess');
    }
  };

  const onBeforeValidationCallback = (eventObj) => {
    if (eventObj.screen === 'gigya-register-screen') {
      // validate the password for common phrases
      const value = passwordValidation(
        eventObj.formData.password,
      );

      if (value.isCommonPhrase) {
        return {
          password: 'Please do not use common phrases as your password',
        };
      }
    }
    return null;
  };
  /**
   * An event function that triggers Gigya screen-set loads. Gigya has RAC templates for different authentication stages.
   * Used in gigya.accounts.showScreenSet.
   * https://help.sap.com/viewer/8b8d6fffe113457094a17701f63e3d6a/GIGYA/en-US/413a5b7170b21014bbc5a10ce4041860.html
   * @param    {Object} e  An event object. Expecting the value of the current displayed Gigya screen.
   */
  const loginCta = (e) => {
    e.preventDefault();
    gigyaClient?.showScreenSet(onBeforeSubmitCallback, onAfterSubmitCallback, onAfterScreenLoadCallback);
  };

  const loginOnTimeEmailCta = (e) => {
    e.preventDefault();
    gigyaClient?.showScreenSetPasswordLess(onBeforeSubmitCallback, onAfterSubmitCallback, onAfterScreenLoadCallback);
  };

  const registerCta = (e) => {
    e.preventDefault();
    gigyaClient?.showRegistrationScreenSet(onBeforeSubmitCallback, onAfterSubmitCallback, onAfterScreenLoadCallback, onBeforeValidationCallback);
  };

  const verifyApprovedCta = (url) => {
    navigate(url);
    // window.location.href = window.location.origin + process.env.PUBLIC_URL + url;
  };

  const verifyRedirectToLoginCta = (url) => {
    navigate(url);
  };

  /**
   * Function that distributes content from content stack as props to the correct React component.
   * @param { Array.<{component: React.Component, id: string, new_entry: string}>} componentItems  An array of component items from content stack.
   */
  const getLandingPageComponents = (componentItems) => componentItems.map((componentItem) => {
    let componentData = componentItem;
    // We need to pass the ref to loginCTA function here.
    // eslint-disable-next-line no-underscore-dangle
    if (componentItem._content_type_uid === LANDING_BANNER_COMPONENT) {
      componentData = {
        ...componentItem,
        loginCtaHandler: loginCta,
        loginOneTimeEmailCtaHandler: loginOnTimeEmailCta,
        registrationFormMyRacHandler: registerCta,
      };
    }
    return {
      id: componentItem.uid,
      // eslint-disable-next-line no-underscore-dangle
      component: componentMappingService(componentItem._content_type_uid, componentData),
    };
  });

  // eslint-disable-next-line no-unused-vars
  const getVerifyComponentItem = (componentItems) => componentItems.map((componentItem) => {
    let componentData = componentItem;

    const redirectParams = new URLSearchParams(searchParams);
    // delete uid param as not needed
    redirectParams.delete('uid');
    const redirectPage = `${userJourneyState.pageHistory[userJourneyState.pageHistory.length - 1]}?${redirectParams}`;

    // We need to pass the ref to loginCTA function here.
    // eslint-disable-next-line no-underscore-dangle
    if (componentItem._content_type_uid === VERIFY_USER_DETAILS_COMPONENT) {
      componentData = {
        ...componentItem,
        redirectPage,
        gigyaUserId: searchParams.get('uid'),
        verifyApprovedHandler: verifyApprovedCta,
        verifyRedirectToLogin: verifyRedirectToLoginCta,
      };
    }
    return {
      id: componentItem.uid,
      // eslint-disable-next-line no-underscore-dangle
      component: componentMappingService(componentItem._content_type_uid, componentData),
    };
  });

  useEffect(() => {
    // Refetch messages content on homepage as session logout clears store but first useEffect() in MyRac.jsx does not act until first render
    async function reloadMessages() {
      try {
        await populateMessages();
        logger.debug('Messages loaded.');
      } catch (e) {
        logger.error(e);
      }
    }
    // Async function that updates page model state if not present with content from content stack
    const getPageModel = async (contentType) => {
      const pageData = await GetContent(contentType, null, ['componentitems', 'componentitems.stack.faqs']);

      if (pageData === undefined || pageData.responseCode === ResponseCode.ERROR) {
        return { error: DefaultText.APIError };
      }

      const isVerifyPage = contentType === 'verifyuserdetailspage';

      return {
        title: pageData.payload.pagetitle,
        components: isVerifyPage ? getVerifyComponentItem(pageData.payload.componentitems) : getLandingPageComponents(pageData.payload.componentitems),
        ...(isVerifyPage ? null : { panels: [{ key: 'login' }, { key: 'callouts' }, { key: 'serpentine' }] }),
      };
    };

    if (isLoggedIn) {
      navigate(RouteConstants.ROOT);
      return;
    }

    const userId = searchParams.get('uid');
    const lastPageRoute = userJourneyState.pageHistory[userJourneyState.pageHistory.length - 1];
    const routeToUse = supportedVerificationRoutes.find(({ path }) => path === lastPageRoute);

    if (userId && routeToUse) {
      trackEvent(routeToUse.trackingValue);
      // we do not want to display the one time email if coming from an email campaign
      // trying to reduce the security risk.
      setOneTimeEmailLoginDisabled(true);
      getPageModel('verifyuserdetailspage').then((response) => {
        setPageModel(response);
        setPageLoading(false);
      });
      return;
    }

    getPageModel('myraclandingpages').then((response) => {
      setPageModel(response);
      setPageLoading(false);
      reloadMessages();
      checkIfOneTimeEmailLogin();
    });

    // used for the verify/missing data in the gigya screen
    // we cannot link to callback for this, so have to use variable instead of state field.
    if (searchParams.get('gig_i_vToken')) {
      window.addEventListener('mousedown', () => {
        if (document.querySelector('.gigya-screen-dialog-main')) {
          missingDataGigyaScreenDisplayed = true;
        }
      });
    }
  }, []); // eslint-disable-line

  // eslint-disable-next-line no-unused-vars
  const handleJWTResponse = (jwtResponse) => {
    if (jwtResponse.status === 'OK') {
      if (jwtResponse.id_token !== null) {
        setJwtToken(jwtResponse.id_token);
      } else {
        handleError(new Error('id_token not found'), getMessage());
      }
    } else {
      handleError(new Error(jwtResponse.status), getMessage());
    }
    setPageLoading(false);
  };

  const loggedOutView = () => {
    /*
     this needs to be reset else customer data is not retrieved on next login attempt.
     Logout function doesn't propagate to RegistrationLogin so we've no way to tell other
     than this.
    */
    if (customerDataRequested) {
      setCustomerDataRequested(false);
    }

    if (pageModel?.components) {
      return pageModel?.components?.map((comp) => <Fragment key={comp.id}>{comp.component}</Fragment>);
    }
    return <Spinner defaultSpinner />;
  };

  const onErrorModalClose = () => {
    setErrorText(undefined);
    setErrorHeaderText(undefined);
  };

  const fullyLoggedIn = hasValidToken();
  const path = location.pathname;

  return (
    <div className="unknown-welcome">
      <ComponentView
        showLoading={appState.loading.status}
        errorHeaderText={errorHeaderText || loginError?.errorTitle}
        errorText={errorText || loginError?.errorBody}
        onErrorModalClose={loginError?.closeButtonAction || onErrorModalClose}
      >
        {fullyLoggedIn !== tokenStatus.isInvalid || path !== RouteConstants.LOGIN
          ? children || (!isLoggedIn && (loggedOutView()))
          : loggedOutView()}
      </ComponentView>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setSessionInfo: (session) => {
    dispatch(SessionActions.setSessionInfo(session));
  },
  setJwtToken: (jwtToken) => {
    dispatch(SessionActions.setJwtToken(jwtToken));
  },
  setOneTimeEmailLoginDisabled: (oneTimeEmailDisabled) => {
    dispatch(SessionActions.setOneTimeEmailLoginDisabled(oneTimeEmailDisabled));
  },
  setLoginMethodUsed: (method) => {
    dispatch(SessionActions.setLoginMethodUsed(method));
  },
});

const mapStateToProps = (state) => ({
  isLoggedIn: state.session.isLoggedIn,
  oneTimeEmailDisabled: state.session.oneTimeEmailDisabled,
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationLogin);
