import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import memberSinceDateDisplay from 'utils/date';

const useGlobalDataLayer = () => {
  const policyInfo = useSelector((state) => state.policy.policyInfo);
  const session = useSelector((state) => state.session);

  const globalDataLayer = () => {
    const payload = {
      MyRAC_CoverType: policyInfo?.CoverType?.Title,
      MyRAC_CoverTypeCode: policyInfo?.DriveCoverTypeCode,
      MyRAC_CoverPeriod: policyInfo?.coverPeriod,
      MyRAC_DaysOfCoverLeft: policyInfo?.DaysOfCoverLeft,
      MyRAC_DaysToRenewal: policyInfo?.DaysToRenewal,
      MyRAC_FreeUpgradeCode: policyInfo?.DriveFreeCoverTypeCode,
      MyRAC_FreeUpgradeType: policyInfo?.DriveFreeCoverType,
      MyRAC_MembershipType: policyInfo?.MembershipType,
      MyRAC_MemberSinceTimeRange: memberSinceDateDisplay(policyInfo?.MemberSince),
      MyRAC_MemberSinceDate: policyInfo?.MemberSince,
      MyRAC_Package: policyInfo?.IsPackage,
      MyRAC_PaymentFrequency: policyInfo?.RenewalType,
      MyRAC_PaymentMethod: null,
      MyRAC_SourceCode: policyInfo?.sourceCode,
      MyRAC_Upgrades_Displayed: null,
      MyRAC_Upgrades_Confirmed: null,
      GigyaId: session?.uid,
      MembershipNo: session?.memberNo,
    };
    window.globalDataLayer = payload;
  };

  useMemo(() => {
    globalDataLayer();
  }, []);
};

export default useGlobalDataLayer;
