import React from 'react';
import styles from './TermsAndConditions.module.scss';

const TermsAndConditions = ({ smrDiscountOfferAmount, renewalDiscountOfferAmount }) => (
  <div className={styles.termsAndConditions}>
    <p id="termsAndConditions"><u>Terms and Conditions</u></p>
    <h4>£{smrDiscountOfferAmount} off a service at home</h4>
    <ol>
      <li>
        Save on a full service or interim service with RAC Mobile Mechanics - compared
        to prices available for non-discounted customers online
        at <a href="https://www.rac.co.uk/car-care/mobile-mechanic" target="_blank" rel="noreferrer">www.rac.co.uk/car-care/mobile-mechanic</a>
      </li>
      <li>
        This reward can be claimed up until
        your renewal/annual review date.
      </li>
      <li>
        Once claimed, you will receive a redemption email on your renewal date.
        This email will contain a unique link to book your discounted service.
        The link will be valid for 12-months from the date of the email.
      </li>
      <li>
        Your discount will be applied to your quoted price,
        at the time of booking.
      </li>
      <li>
        The discount cannot be used with any other discount
        or offer on mobile mechanics.
      </li>
    </ol>
    <h4>£{renewalDiscountOfferAmount} off your breakdown cover</h4>
    <ol>
      <li>
        You can redeem this reward up until the point your renewal payment is collected.
        If your payment is already being processed,
        we cannot apply your discount,
        so will issue your discount
        as a refund or reduce your next 11 payments.
      </li>
      <li>
        Cannot be used in conjunction
        with any other offer or discount.
      </li>
      <li>
        If you cancel a monthly policy before the twelfth payment,
        you will not be able to claim back the remaining discount.
      </li>
    </ol>

    <h4>Offer terms</h4>
    <p>
      The rewards are subject to availability and may be withdrawn at any time.
      Points must be spent in one transaction,cannot be exchanged for cash,
      and are non-refundable once used.
      Only valid for the lead member and RAC membership must be live
      at the time of redemption.
    </p>
  </div>
);

export default TermsAndConditions;
