import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { dispatch } from 'store';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import { setDirectDebitData } from 'actions/renewalActions';
import { RENEWAL, RENEWAL_ERROR } from 'constants/routeConstants';
import TextButton from 'shared/components/TextButton/TextButton';
import { GetRenewalPaymentIdAndLockToken } from 'services/MyRacService';
import trackEvent from 'services/AnalyticsService';
import backIcon from 'images/icons/caret-left-black.svg';
import ddLogo from 'images/paymentPage/directdebit.png';
import RenewalPaymentIFrame from 'components/PaymentService/Renewal/PaymentIFrame';
import DirectDebitForm from 'components/DirectDebit/Form/DirectDebitForm';
import Spinner from 'components/Spinner';
import PopupModal from 'components/PopupModal/PopupModal';
import TimeoutWarningModal from 'components/TimeoutWarningModal';
import modalTypes from 'utils/modalTypesEnum';
import styles from './CombinedPaymentsScreen.module.scss';

const PaymentHeader = ({ children, closed }) => (
  <div className={closed ? `${styles.paymentHeader} ${styles.closed}` : styles.paymentHeader}>
    {children}
  </div>
);

const CombinedPaymentsScreen = (props) => {
  const { route } = props;
  const [policyLockToken, setPolicyLockToken] = useOutletContext();
  const pageModel = useSelector((state) => state.renewal.pageModel);
  const renewalPaymentPayload = useSelector((state) => state.renewal.paymentPayload);

  const [loading, setLoading] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModal, setErrorModal] = useState({});
  const [reloadRenewal, setReloadRenewal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [isTermsModal, setTermsModal] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [firstMonthPaymentModal, setFirstMonthPaymentModal] = useState(false);
  const [directDebitDataValid, setDirectDebitDataValid] = useState(false);
  const [directDebitEdit, setDirectDebitEdit] = useState(false);
  const navigate = useNavigate();

  const showDirectDebit = !directDebitDataValid || directDebitEdit;

  const onBackClick = async () => {
    if (route === RENEWAL) {
      trackEvent('MyRAC_Renewal_Combined_Back');
      dispatch(setDirectDebitData(null));
      navigate(RENEWAL, { state: { reloadRenewal } });
    }
  };

  const onModalClose = () => {
    setShowInfoModal(false);
    setTermsModal(false);
  };

  const onModalClick = () => {
    if (route === RENEWAL) {
      navigate(RENEWAL, { state: { reloadRenewal } });
    }
  };

  const displayTermsModal = () => {
    setShowInfoModal(true);
    // Boolean flag used to switch between content in modal
    setTermsModal(true);
  };

  const openDirectDebitEdit = () => {
    setDirectDebitDataValid(false);
    setDirectDebitEdit(true);
  };

  const fetchPaymentId = async () => {
    try {
      const response = await GetRenewalPaymentIdAndLockToken();
      // Payload determines eligibility
      if (response?.payload) {
        const { createdPaymentId, lockToken, displayErrorMessage, errorMessage, errorTitle, errorReloadRisk } = response.payload;

        if (displayErrorMessage) {
          setErrorModal({ message: errorMessage, title: errorTitle });
          setReloadRenewal(errorReloadRisk);
          if (errorReloadRisk) {
            // true
            trackEvent('MyRAC_Renewal_PaymentError_PolicyUpdated');
          } else {
            // false
            trackEvent('MyRAC_Renewal_PaymentError_PolicyLocked');
          }
          setShowErrorModal(true);
          setLoading(false);
          return;
        }

        setPaymentId(createdPaymentId);
        setPolicyLockToken(lockToken);
      } else {
        // Redirect to error page as not eligibile
        navigate(RENEWAL_ERROR, { state: { isPaymentError: false }, replace: true });
      }
      setLoading(false);
    } catch (e) {
      navigate(RENEWAL_ERROR, { state: { isPaymentError: false }, replace: true });
    }
  };

  useEffect(() => {
    fetchPaymentId();
    trackEvent('MyRAC_Renewal_Combined_Viewed');
  }, []);

  if (loading && !pageModel) {
    return <Spinner bootSpinner />;
  }
  return (
    <div className="container din-font">
      <div className={styles.nav}>
        <TextButton buttonText="Back" onClickHandler={onBackClick} iconImage={backIcon} iconImageAltText="Back" noUnderline />
      </div>
      <div className={styles.header}>
        <h2 className={styles.title}>Pay monthly</h2>
        We need to set up <b>two separate payments</b>. You&apos;ll need to pay for your first month up-front
        and we&apos;ll set up your Direct Debit for your monthly instalments.
      </div>
      <div className={styles.container}>
        <PaymentHeader closed={!showDirectDebit}>
          <h3>
            <b>Payment 1</b>
            <p>Set up Direct Debit</p>
            <span>Ongoing payments</span>
          </h3>
          <div>
            £{pageModel.basketContent?.monthlyHeadlinePriceNow.toFixed(2)}
            {directDebitDataValid
              ? <button type="button" onClick={openDirectDebitEdit}>Edit payment details</button>
              : <span>&nbsp;</span>}
          </div>
        </PaymentHeader>
        {showDirectDebit && (
          <div className={styles.compactVersionContainer}>
            <img src={ddLogo} alt="Direct Debit" />
            <h3>Your bank details</h3>
            <DirectDebitForm
              payload={renewalPaymentPayload}
              onError={setShowErrorModal}
              onInvalid={setShowInfoModal}
              renewalToken={policyLockToken}
              showPrice={false}
              summaryType="monthlyFlexSummary"
              displayTermsModal={displayTermsModal}
              setDirectDebitDataValid={setDirectDebitDataValid}
              setDirectDebitEdit={setDirectDebitEdit}
              useCounter={false}
              useCompactVersion
            />
          </div>
        )}
        <PaymentHeader closed={showDirectDebit}>
          <h3>
            <b>Payment 2</b>
            <p>One time card payment</p>
            <span>The first month</span>
          </h3>
          <div>
            £{pageModel.basketContent?.depositPrice.toFixed(2)}
            <button type="button" onClick={() => setFirstMonthPaymentModal(true)}>Info</button>
          </div>
        </PaymentHeader>
        {!showDirectDebit && paymentId && policyLockToken && renewalPaymentPayload && (
          <RenewalPaymentIFrame
            paymentId={paymentId}
            payload={renewalPaymentPayload}
            renewalToken={policyLockToken}
            onSuccess={() => {
              window.globalDataLayer.MyRAC_Renewal_Monthly_Flex_Journey_Completed = true;
              dispatch(setDirectDebitData(null));
            }}
            useCompactVersion
            noTimeoutModal
          />
        )}
      </div>
      <div className={styles.clear} />
      {firstMonthPaymentModal
        && (
          <PopupModal
            innerCloseButton
            action={() => setFirstMonthPaymentModal(false)}
            resultTitle="One time card payment"
            resultText="We can&apos;t always divide the price of your cover into 12 equal payments.
              This is why your one-time payment may be slightly higher
              or lower than your monthly Direct Debit."
            displayDialogInMiddle
            modalType={modalTypes.infoModal}
          />
        )}
      {showErrorModal
        && (
          <PopupModal
            innerCloseButton
            modalOk="Ok"
            action={onModalClick}
            resultTitle={errorModal.title}
            resultText={errorModal.message}
            displayDialogInMiddle
          />
        )}
      {showInfoModal
        && (
          <PopupModal
            innerCloseButton
            modalOk="Ok"
            action={onModalClose}
            resultTitle={isTermsModal ? pageModel?.directDebitPage?.directDebitGuaranteeModel?.header : renewalPaymentPayload?.DirectDebitPage?.errorTitle}
            resultText={isTermsModal ? ReactHtmlParser(pageModel?.directDebitPage?.directDebitGuaranteeModel?.body) : renewalPaymentPayload?.DirectDebitPage?.errorBody}
            displayDialogInMiddle
          />
        )}
      <TimeoutWarningModal policyLockToken={policyLockToken} />
    </div>
  );
};

export default CombinedPaymentsScreen;
