import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import { selectRenewalFreeLoyaltyReward, removeRenewalFreeLoyaltyReward } from 'actions/renewalActions';
import adobeOfferStatusConstants from 'constants/adobeOfferStatusConstants';
import { selectedAdobeOfferStatus } from '../../../../utils/renewalHelpers';
import PopupModal from '../../../../components/PopupModal/PopupModal';
import styles from './MoreForMoreExtra.module.scss';
import infoIconSvg from '../../../../assets/images/button/info-blue.svg';
import tickIconSvg from '../../../../assets/images/button/tick-circle-solid-green.svg';
import circleIconSvg from '../../../../assets/images/button/circle-orange.svg';
import trackEvent from '../../../../services/AnalyticsService';

const FreeLoyaltyRewards = () => {
  const pageModel = useSelector((state) => state.renewal.pageModel);
  const featureTag = useSelector((state) => state.renewal.basket.featureTag);
  const selectedFLR = useSelector((state) => state.renewal.basket.freeLoyaltyReward);

  const chosenFLRCode = selectedFLR.propositionId;
  const rewards = pageModel.freeLoyaltyRewards;
  const analyticsTag = `${featureTag}_Offer_Select`;

  const selectedStatus = selectedAdobeOfferStatus(pageModel?.adobeOfferStatus, selectedFLR.propositionId) !== adobeOfferStatusConstants.NoOfferSelected;

  const [modalData, setModalData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const onFindOutMoreClick = (rewardTitle, rewardDescription) => {
    trackEvent('MyRAC_Renewal_AdditionalM4M_Info');
    setModalData({ title: rewardTitle, description: rewardDescription });
    setShowModal(true);
  };

  const onRewardCheckboxClick = (reward) => {
    const offerCode = reward.propositionId;
    if (chosenFLRCode === offerCode) {
      dispatch(removeRenewalFreeLoyaltyReward());
    } else {
      trackEvent(analyticsTag);
      dispatch(selectRenewalFreeLoyaltyReward(reward));
    }
  };

  useEffect(() => {
    if (rewards && selectedFLR.propositionId) {
      dispatch(removeRenewalFreeLoyaltyReward());
      rewards.forEach((reward) => {
        if (reward.adobeCode === selectedFLR.adobeCode && selectedStatus !== adobeOfferStatusConstants.NoOfferSelected) {
          dispatch(selectRenewalFreeLoyaltyReward(reward));
        }
      });
    }
  }, []);

  return (
    <>
      <div className="row">
        <div className="col">
          <div className={`card ${chosenFLRCode ? 'border border-success' : 'border-orange'}`}>
            <div className={`card-header text-center text-white ${chosenFLRCode ? 'bg-success' : 'bg-orange'}`}>Claim your reward</div>
            {rewards && rewards.sort((a, b) => b.adobeCode.localeCompare(a.adobeCode)).map((reward) => (
              <div key={`freeOffer-${reward.propositionId}`}>
                <button
                  onClick={() => onRewardCheckboxClick(reward)}
                  className={`${styles.freeButton} ${chosenFLRCode === reward.propositionId && styles.freeSelected}`}
                  type="button"
                >
                  <div className={styles.tickIcon}>
                    {chosenFLRCode === reward.propositionId
                      ? (<img src={tickIconSvg} alt="info" height="28" width="28" />)
                      : (<img src={circleIconSvg} alt="info" height="28" width="28" />)}
                  </div>
                  <div className={styles.rewardBox}>
                    <span className={styles.rewardTitle}>
                      <span className={chosenFLRCode === reward.propositionId ? 'text-success' : styles.price}>FREE </span>
                      {reward.title}
                    </span>
                    <div className={styles.rewardBody}>
                      <p>{reward.productShortDescription}</p>
                    </div>
                  </div>
                </button>
                <div className={styles.rewardInfo}>
                  <img src={infoIconSvg} alt="info" height="18" width="18" className={styles.icon} />
                  <span
                    onClick={() => onFindOutMoreClick(reward.title, reward.productLongDescription)}
                    className={styles.findOutMoreLink}
                    role="link"
                    onKeyDown={() => onFindOutMoreClick(reward.title, reward.productLongDescription)}
                    tabIndex={0}
                  >
                    Find out more
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showModal && (
        <PopupModal
          resultText={modalData.description}
          resultTitle={modalData.title}
          action={() => setShowModal(false)}
          displayDialogInMiddle
          innerCloseButton
          parseHtml
        />
      )}
    </>
  );
};

export default FreeLoyaltyRewards;
