import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dispatch } from 'store';
import { setDirectDebitData } from 'actions/renewalActions';
import PaymentSummary from 'components/DirectDebit/PaymentSummary';
import InfoBox from 'components/DirectDebit/InfoBox';
import CombinedPaymentsInfoBox from 'components/CombinedPayments/InfoBox';
import { SetRenewalDirectDebitStatus } from '../../../services/MyRacService';
import { RENEWAL_CONFIRMATION, RENEWAL_ERROR } from '../../../constants/routeConstants';
import styles from './DirectDebitForm.module.scss';
import Button from '../../../shared/components/Button/Button';

const DirectDebitForm = ({
  payload,
  onError,
  onInvalid,
  renewalToken,
  showPrice = true,
  useCounter = true,
  useCompactVersion = false,
  summaryType,
  displayTermsModal,
  setDirectDebitDataValid,
  setDirectDebitEdit,
}) => {
  const navigate = useNavigate();
  const directDebitData = useSelector((state) => state.renewal.basket.directDebitData);
  const [formState, setFormState] = useState(directDebitData);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [validationError, setValidationError] = useState({
    invalidName: false,
    invalidAccountNo: false,
    invalidSortCode: false,
  });

  const formValidation = () => {
    const invalidName = !formState?.Name;
    const invalidAccountNo = !formState?.AccountNo || formState?.AccountNo.length < 8;
    const invalidSortCode = !formState?.SortCode || formState?.SortCode.length < 6;

    setValidationError({ invalidName, invalidAccountNo, invalidSortCode });

    return !invalidName && !invalidAccountNo && !invalidSortCode;
  };

  const handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    const updatedInputs = { ...formState };
    updatedInputs[name] = value;

    // if the user starts entering data in a field with an error, the error should disappear
    const updatedValidationError = validationError;
    updatedValidationError[`invalid${name}`] = false;
    setValidationError(updatedValidationError);

    setFormState(() => (updatedInputs));
  };

  const validateTextEntry = (e) => {
    // Only allow alpha letters, hyphens or apostrophes to be entered into name field
    if (!/^[a-zA-Z-' ]+$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const validateNumericEntry = (e) => {
    // Only allow numeric values to be entered into field
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const validateSubmit = async () => {
    try {
      const response = await SetRenewalDirectDebitStatus(
        payload.CustomerId,
        payload.MembershipNumber,
        payload.PolicyNumber,
        payload.Price,
        payload.RenewalRiskVersion,
        renewalToken,
        formState?.Name,
        formState?.AccountNo,
        formState?.SortCode,
      );

      // Payload determines if account details are valid
      if (response?.payload) {
        const { isDirectDebitSuccessful, maxAttemptNumberReached } = response.payload;
        setLoading(false);

        if (isDirectDebitSuccessful) {
          // Submit details to SSP and navigate to confirmation page
          window.globalDataLayer.MyRAC_Upgrades_Confirmed = payload.Amendments?.length > 0 ? payload.Amendments.map((upgrade) => upgrade.title) : [];

          if (payload.MemberChoseSwitchToMonthlyFlex) {
            dispatch(setDirectDebitData(formState));
            setDirectDebitDataValid(true);
            setDirectDebitEdit(false);
            return;
          }
          navigate(RENEWAL_CONFIRMATION, { state: { isDirectDebit: true, ...payload }, replace: true });
        }
        if (maxAttemptNumberReached || (useCounter && count === 3)) {
          // Navigate to error page if member submits incorrect details on 3 occasions
          navigate(RENEWAL_ERROR, { state: { isPaymentError: true, ...payload }, replace: true });
        } else {
          // Invalid account details so boolean returned to parent component to showInvalidModal
          onInvalid(true);
        }
      }
    } catch (e) {
      // Request failed so boolean returned to parent component to showErrorModal
      onError(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Return true to submit values or false which invokes validation errors on form
    const isValid = formValidation();
    // Filter true values returned from 'state.form' and count to make sure all fields are completed
    const formValues = formState && Object.values(formState).filter((item) => item).length;
    // Combine checks
    if (formValues === 3 && isValid) {
      if (useCounter) {
        setCount(count + 1);
      }
      setLoading(true);
      validateSubmit();
    }
  };

  useEffect(() => {
    if (setDirectDebitEdit && directDebitData) {
      setFormState(directDebitData);
    }
  }, [setDirectDebitEdit]);

  return (
    <div className={useCompactVersion ? styles.compactVersion : styles.wrapper}>
      <form id="DirectDebitForm" className={useCompactVersion ? '' : 'w-100 px-4 px-md-5'}>
        {showPrice && !payload?.MonthlyLongerTermPolicy ? (
          <div className="fs-3 mb-3">
            £{payload?.Price?.toFixed(2)}
          </div>
        ) : <br />}
        <label htmlFor="Name" className={useCompactVersion ? 'form-label w-100 mb-4 align-top' : 'form-label w-100 align-top'}>
          {useCompactVersion ? '' : 'Account holderʼs name'}
          <input
            aria-label="Account holder name"
            name="Name"
            onPaste={validateTextEntry}
            onKeyPress={validateTextEntry}
            onChange={handleInputChange}
            className={`${validationError?.invalidName && 'is-invalid'} form-control form-control-lg`}
            type="text"
            maxLength="50"
            placeholder={useCompactVersion ? 'Account holderʼs name' : ''}
            defaultValue={directDebitData?.Name}
          />
          <small className={validationError?.invalidName ? 'd-flex invalid-feedback' : 'd-none'}>
            Please enter name on the account
          </small>
        </label>
        {!useCompactVersion && <br />}
        <label htmlFor="AccountNo" className={useCompactVersion ? 'form-label col-12 col-md-8 mb-4 mb-md-0 align-top' : 'form-label w-100 align-top'}>
          {useCompactVersion ? '' : 'Account number'}
          <input
            aria-label="Account number"
            name="AccountNo"
            onPaste={validateNumericEntry}
            onKeyPress={validateNumericEntry}
            onChange={handleInputChange}
            className={`${validationError?.invalidAccountNo && 'is-invalid'} form-control form-control-lg`}
            type="text"
            maxLength="8"
            placeholder={useCompactVersion ? 'Account number' : ''}
            inputMode="numeric"
            defaultValue={directDebitData?.AccountNo}
          />
          <small className={validationError?.invalidAccountNo ? 'd-flex invalid-feedback' : 'd-none'}>
            Please enter a valid account number
          </small>
        </label>
        {!useCompactVersion && <br />}
        <label htmlFor="SortCode" className={useCompactVersion ? 'form-label col-12 col-md-3 offset-md-1 mb-3 align-top' : 'form-label w-100 mb-4 align-top'}>
          {useCompactVersion ? '' : 'Sort code'}
          <input
            aria-label="Sort code"
            name="SortCode"
            onPaste={validateNumericEntry}
            onKeyPress={validateNumericEntry}
            onChange={handleInputChange}
            className={`${validationError?.invalidSortCode && 'is-invalid'} form-control form-control-lg`}
            type="text"
            maxLength="6"
            placeholder={useCompactVersion ? 'Sort code' : ''}
            inputMode="numeric"
            defaultValue={directDebitData?.SortCode}
          />
          <small className={validationError?.invalidSortCode ? 'd-flex invalid-feedback' : 'd-none'}>
            Please enter a valid sort code
          </small>
        </label>
        {/* useCompactVersion is used for Monthly Flex and Direct Debit monthly */}
        {useCompactVersion && (
          <>
            <PaymentSummary monthlyFlexSummary={summaryType === 'monthlyFlexSummary'} />
            {summaryType === 'monthlyFlexSummary'
              ? <CombinedPaymentsInfoBox onClickHandler={displayTermsModal} />
              : <InfoBox onClickHandler={displayTermsModal} />}
          </>
        )}
        <Button onClickHandler={handleSubmit} loading={loading} buttonText={useCompactVersion ? 'Continue' : 'Pay now'} chevronWhite={!useCompactVersion} />
      </form>
    </div>
  );
};

export default DirectDebitForm;
