import React from 'react';
import { connect } from 'react-redux';
import IsLoading from 'common/IsLoading';
import useViewport from '../../../../utils/useViewportHelper';
import TileWrapper from './TileWrapper';
import './GridLayout.scss';

export const AbstractGrid = (props) => {
  const { tiles, columns } = props;

  const notificationLinks = [];
  // GridLayout needs to have a way to store all an array of tileID which it will pass to welcomeTile as props

  // Captures a change to the mobile layout and causes a new render with subsequent re-request of the grid data
  const currentMobileState = useViewport().isBootstrapSm;

  const setGridCss = (inputTiles) => {
    const tileCss = [];

    inputTiles.forEach((tile) => {
      tileCss.push({
        row: `span ${tile.rows}`,
        column: `span ${tile.columns}`,
      });
    });

    return tileCss;
  };

  const getLoading = () => (
    <div className="gridLoading">
      <IsLoading />
    </div>
  );

  const mobileRender = () => {
    if (tiles && tiles.length) {
      return (
        <div className="mobileLayout">
          {
            tiles.map((tile) => {
              if (tile.tileContent !== null) {
                const tileID = `${tile.content_Type_UID}_${tile.tileContent.title}`.replace(/\s+/g, '');
                if (tile.isNotifiable) {
                  notificationLinks.push(tileID);
                }
                return (
                  <div key={tileID} id={tileID}>
                    <TileWrapper tileData={tile} />
                  </div>
                );
              }
              return null;
            })
          }
        </div>
      );
    }
    return getLoading();
  };

  const buildGrid = () => {
    if (tiles && tiles.length) {
      const tileCss = setGridCss(tiles);
      return (
        <div style={{ gridTemplateColumns: '1fr '.repeat((columns)) }} className="gridLayout">
          {
            tiles.map((tile, item) => {
              const index = `${tile.content_Type_UID}_${item}`;
              if (tile.tileContent !== null) {
                return (
                  <div
                    className="cell"
                    style={{ gridColumn: tileCss[item].column, gridRow: tileCss[item].row }}
                    key={index}
                  >
                    <TileWrapper tileData={tile} />
                  </div>
                );
              }
              return null;
            })
          }
        </div>
      );
    }
    return getLoading();
  };

  return currentMobileState ? mobileRender() : buildGrid();
};

const mapStateToProps = (state) => ({
  featureFlags: state.featureToggle,
});

export default connect(mapStateToProps)(AbstractGrid);
