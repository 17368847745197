import React from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import styles from './CombinedPaymentsScreen.module.scss';

const InfoBox = ({ onClickHandler, image }) => {
  const pageModel = useSelector((state) => state.renewal.pageModel);

  return (
    <div className={styles.info}>
      <div className={styles.greybox}>
        {image || ''}
        <p className="mt-3">{pageModel.directDebitPage?.directDebitTermsAndConditionsModel?.header}</p>
        {ReactHtmlParser(pageModel.directDebitPage?.directDebitTermsAndConditionsModel?.body)}
        <p><span onClick={onClickHandler} aria-hidden="true">Direct Debit instructions & guarantee</span></p>
      </div>
    </div>
  );
};

export default InfoBox;
