import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import logger from 'redux-logger';
import rootReducer from 'reducers/rootReducer';
import { createBrowserHistory } from 'history';
import immutableStateInvariantMiddleware from 'redux-immutable-state-invariant';
import ldRedux from '@svey/ld-redux';
import { throttle } from 'lodash';
import { createReduxHistoryContext } from 'redux-first-history';
import { loadState, saveState } from './sessionStorage/sessionStorage';

const { createReduxHistory, routerMiddleware } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const getMiddleware = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return composeWithDevTools();
    case 'local':
      return composeWithDevTools();
    case 'uat':
    case 'sit':
      return composeWithDevTools();
    case 'pre':
      return applyMiddleware(routerMiddleware, logger, immutableStateInvariantMiddleware({
        ignore: [
          'router',
          'content',
        ],
      }));
    case 'prd':
      return applyMiddleware(routerMiddleware);
    default:
      return applyMiddleware(routerMiddleware);
  }
};

const persistedState = loadState();
export const store = createStore(
  rootReducer(),
  persistedState,
  getMiddleware(),
);

// Listening for state change in the store which will be used to set keys in sessionStorage using saveState fn;
store.subscribe(throttle(() => {
  saveState(store);
}, 1000));

let testOverrideCookie;
if (document.cookie) {
  // eslint-disable-next-line prefer-destructuring
  testOverrideCookie = document.cookie?.split('; ')?.find((row) => row.startsWith('TestOverride'))?.split('=')[1];
}

const testUser = {
  key: 'testuser',
  email: 'testuser@email.com',
  name: 'test user',
  firstName: 'test',
  lastName: 'user',
};

// create a specific user so that the Launch Darkly MAU is limited to
// minimal user.  Currently 2000% overuse.
const user = {
  key: 'racuser',
  email: 'racuser@email.com',
  name: 'myrac user',
  firstName: 'myrac',
  lastName: 'user',
};

ldRedux.init({
  clientSideId: process.env.REACT_APP_LAUNCH_DARKLY,
  dispatch: store.dispatch,
  context: testOverrideCookie ? testUser : user,
});

export const history = createReduxHistory(store);

/*
* Redux store getState().
* If store does not exist produce an error.
*/
export const getState = () => {
  if (!store) {
    throw Error('Store is not configured. Call configureStore first.');
  }
  return store.getState();
};

/*
* Dispatches action to Redux store. Produces an error if store does not exist.
* @param {type: string, action: } action The action to be dispatched.
* @return {number} x raised to the n-th power. //edit this
*/
export const dispatch = (action) => {
  if (!store) {
    throw Error('Store is not configured. Call configureStore first.');
  }
  return store.dispatch(action);
};
