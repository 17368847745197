export const SET_RENEWAL_PAGE_MODEL = 'SET_RENEWAL_PAGE_MODEL';
export const SET_RENEWAL_FEATURE_TAG = 'SET_RENEWAL_FEATURE_TAG';
export const SET_RENEWAL_PAYMENT_PAYLOAD = 'SET_RENEWAL_PAYMENT_PAYLOAD';
export const SELECT_RENEWAL_UPGRADE = 'SELECT_RENEWAL_UPGRADE';
export const REMOVE_RENEWAL_UPGRADE = 'REMOVE_RENEWAL_UPGRADE';
export const SELECT_RENEWAL_FLR = 'SELECT_RENEWAL_FLR';
export const REMOVE_RENEWAL_FLR = 'REMOVE_RENEWAL_FLR';
export const SET_RENEWAL_VEHICLES = 'SET_RENEWAL_VEHICLES';
export const CHANGE_RENEWAL_VEHICLE = 'CHANGE_RENEWAL_VEHICLE';
export const SET_RENEWAL_AUTORENEW = 'SET_RENEWAL_AUTORENEW';
export const SET_RENEWAL_PAPERLESS = 'SET_RENEWAL_PAPERLESS';
export const SET_CHOSEN_MONTHLY_FLEX_QUOTE = 'SET_CHOSEN_MONTHLY_FLEX_QUOTE';
export const SET_DIRECT_DEBIT_DATA = 'SET_DIRECT_DEBIT_DATA';
