import { useSelector } from 'react-redux';
import priceTrim from 'utils/priceTrim';

const useRenewalPriceCalculator = () => {
  const pageModel = useSelector((state) => state.renewal.pageModel);
  const { discountRenewal } = useSelector((state) => state.session);
  const originalPrice = pageModel?.price;
  const discountedOriginalPrice = discountRenewal?.originalPrice;
  const selectedUpgrades = useSelector((state) => state.renewal.basket.upgrades);

  let addedUpgradesTotal = 0;
  let addedUpgradesTotalDiscounted = 0;

  /**
   * Adding prices of selected paid upgrades
   * upgrades are currently exluded from Direct Debit
   */
  if (selectedUpgrades && selectedUpgrades.length) {
    selectedUpgrades.forEach((upgrade) => {
      if (upgrade?.discountedPrice && upgrade?.price) {
        addedUpgradesTotal += upgrade.discountedPrice;
        addedUpgradesTotalDiscounted += upgrade.price;
      } else if (upgrade?.price) {
        addedUpgradesTotal += upgrade.price;
      }
    });
  }

  /* not taking in to account monthly payments yet and currently only Direct Debit can be monthly */
  const totalPrice = addedUpgradesTotal > 0
    ? priceTrim(originalPrice + addedUpgradesTotal)
    : originalPrice;

  const totalDiscountedPrice = addedUpgradesTotalDiscounted > 0
    ? priceTrim(discountedOriginalPrice + addedUpgradesTotalDiscounted)
    : discountedOriginalPrice;

  return { totalPrice, totalDiscountedPrice };
};

export default useRenewalPriceCalculator;
