import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from 'components/Spinner';
// import trackEvent from 'services/AnalyticsService';
import { GetOrangePointsRewards } from 'services/MyRacService';
import { ROOT } from 'constants/routeConstants';
import TextButton from 'shared/components/TextButton/TextButton';
import TermsAndConditions from 'views/MyRac/OrangePoints/TermsAndConditions';
import OrangePointsOptions from 'views/MyRac/OrangePoints/OrangePointsOptions';
import infoWhiteSvg from 'assets/images/icons/info-full-white.svg';
import backIcon from 'images/icons/caret-left-black.svg';
import pointsBanner from './assets/pointsBanner.gif';
import pointsBannerDesktop from './assets/pointsBannerDesktop.gif';
import styles from './OrangePointsRewardsScreen.module.scss';

const OrangePointsRewardsScreen = () => {
  const [loading, setLoading] = useState(true);
  const [pageModel, setPageModel] = useState(null);
  const navigate = useNavigate();

  // placeholder/todo for useGlobalDataLayer();

  const fetchOrangePointsEligibility = async () => {
    try {
      const response = await GetOrangePointsRewards();
      setPageModel(response);
      setLoading(false);
    } catch (e) {
      // placeholder/todo for trackEvent('MyRAC_OrangePointsRewardsScreen_Server_Error');
      setLoading(false);
      // placeholder/todo for  navigate(ERROR_PAGE, { state: { property: BOOL }, replace: true });
    }
  };

  const onBackClick = async () => {
    // placeholder/todo for trackEvent('MyRAC_OrangePointsRewardsScreen_DirectDebit_Back');
    navigate(ROOT);
  };

  useEffect(() => {
    fetchOrangePointsEligibility();
  }, []);

  if (!pageModel && loading) {
    return <Spinner bootSpinner />;
  }

  if (!pageModel.isEligibleForOrangePointsRewards || !pageModel.isSspBusinessWindowOpen) {
    return (
      <div className={`container ${styles.containerBackground}`}>
        <div className={styles.nav}>
          <TextButton buttonText="Back" onClickHandler={onBackClick} iconImage={backIcon} iconImageAltText="Back" noUnderline />
        </div>
        <div className="text-center p-4 m-4">
          <h1>Error!</h1>
          {(!pageModel.isEligibleForOrangePointsRewards && 'This user is not eligible for Orange Points!')
          || (!pageModel.isSspBusinessWindowOpen && 'SSP Business Window Closed!')}
        </div>
      </div>
    );
  }

  return (
    <div className={`container ${styles.containerBackground}`}>
      <div className={styles.nav}>
        <TextButton buttonText="Back" onClickHandler={onBackClick} iconImage={backIcon} iconImageAltText="Back" noUnderline />
      </div>
      <div className={styles.content}>
        <div className={styles.bannerWrapper}>
          {/* Display different banner for different amount of Orange Points */}
          {pageModel.orangePoints > 0 && (
            <>
              <img className={styles.banner} src={pointsBanner} alt="Orange Points banner" />
              <img className={styles.bannerDesktop} src={pointsBannerDesktop} alt="Orange Points banner" />
            </>
          )}
          <p>
            With the RAC, loyalty pays off.<br />
            Earn Orange Points every year you&apos;re with us, and put them towards discounts that suit you.<br />
            <a href="#termsAndConditions" className={styles.bannerLink}>
              <img width="18px" height="18px" src={infoWhiteSvg} alt="White information icon" />
              Your T&Cs
            </a>
          </p>
        </div>
        {pageModel.smrDiscountOfferAmount && pageModel.renewalDiscountOfferAmount && (
          <OrangePointsOptions
            smrDiscountOfferAmount={pageModel.smrDiscountOfferAmount}
            renewalDiscountOfferAmount={pageModel.renewalDiscountOfferAmount}
          />
        )}
        <TermsAndConditions
          smrDiscountOfferAmount={pageModel.smrDiscountOfferAmount}
          renewalDiscountOfferAmount={pageModel.renewalDiscountOfferAmount}
        />
      </div>
    </div>
  );
};

export default OrangePointsRewardsScreen;
