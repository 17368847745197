import React, { useEffect, useRef } from 'react';
import RenderLink from '../../../components/RenderLink';
import NewMenu from './NewMenu';
import styles from './MainNav.module.scss';

const MainNav = ({ pageModel, toggleMobileMenu, headerTop, setHeaderTop, scroll }) => {
  const navRef = useRef(null);

  useEffect(() => {
    const offset = navRef ? navRef.current.offsetTop : 0;

    if (offset > 0 && !headerTop) {
      // eslint-disable-next-line react/no-did-update-set-state
      setHeaderTop(navRef.current.offsetTop);
    }
  }, [navRef]);

  return (
    <nav className="siteHeader">
      <div className="container">
        <div className="row">
          <div className="col-sm-2 col-6">
            <RenderLink url="/myrac/" isExternal>
              <img
                className={`${(scroll > 80) ? styles.siteLogo : styles.siteLogoTop}`}
                src={pageModel?.header?.logo.url}
                alt={pageModel?.header?.logo.alt}
              />
            </RenderLink>
          </div>
          <div className="col-sm-10 col-6">
            <div className="siteNav" ref={navRef}>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
              <span className="d-block d-md-none" onClick={toggleMobileMenu}>
                <span />
                {' '}
                <span />
                {' '}
                <span />
              </span>
              <NewMenu toggleMobileMenu={toggleMobileMenu} navRef={navRef} pageModel={pageModel} />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MainNav;
