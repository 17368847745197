import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useReferrerParam from 'hooks/useReferrerParam';
import { ReleaseLockToken } from 'services/MyRacService';
import Logo from 'views/MyRac/OrangePoints/Logo';

const OrangePointsView = () => {
  const [policyLockToken, setPolicyLockToken] = useState();

  // Flag to indicate referral from App (Webview)
  const isAppView = useReferrerParam();

  useEffect(() => {
    const releaseToken = async () => {
      if (policyLockToken) {
        await ReleaseLockToken(policyLockToken);
      }
    };

    return () => releaseToken();
  });

  return (
    <div>
      {!isAppView && <Logo />}
      <Outlet context={[policyLockToken, setPolicyLockToken]} />
    </div>
  );
};

export default OrangePointsView;
