import React from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import styles from './DirectDebitScreen.module.scss';

const InfoBox = ({ onClickHandler, image, displayCollectionInfo }) => {
  const pageModel = useSelector((state) => state.renewal.pageModel);

  return (
    <div className={styles.info}>
      <div className={styles.greybox}>
        {image || ''}
        <p className="mt-3">{pageModel.directDebitPage?.directDebitTermsAndConditionsModel?.header}</p>
        {ReactHtmlParser(pageModel.directDebitPage?.directDebitTermsAndConditionsModel?.body)}
        <p><span onClick={onClickHandler} aria-hidden="true">Direct Debit instructions & guarantee</span></p>
        {displayCollectionInfo && pageModel.directDebitPaymentDate && (
          <p className={styles.date}>
            Payment to be collected on or around {format(new Date(pageModel.directDebitPaymentDate), 'd MMM yyyy')}
          </p>
        )}
      </div>
    </div>
  );
};

export default InfoBox;
