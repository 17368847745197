import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import useReferrerParam from '../../hooks/useReferrerParam';
import Navigation from '../../views/MyRac/Renewal/Navigation/Navigation';
import { ReleaseLockToken } from '../../services/MyRacService';

const RenewalsView = (props) => {
  const [policyLockToken, setPolicyLockToken] = useState();
  const { policy } = props;

  // Flag to indicate referral from App (Webview)
  const isAppView = useReferrerParam();

  // Alternative telephone numbers are to be shown for users of either 1-2 or 3+ years membership
  const tenureTelNo = policy?.policyInfo?.yearsOfMembership < 3 ? '0330 159 1417' : '0330 159 8529';

  useEffect(() => {
    const releaseToken = async () => {
      if (policyLockToken) {
        await ReleaseLockToken(policyLockToken);
      }
    };

    return () => releaseToken();
  });

  return (
    <div>
      {!isAppView && <Navigation telNo={tenureTelNo} />}
      <Outlet context={[policyLockToken, setPolicyLockToken]} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
  policy: state.policy,
});

export default connect(mapStateToProps)(RenewalsView);
