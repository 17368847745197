export const ROOT = '/';
export const PERSONAL_DETAILS = '/personal-details';
export const DOCUMENTS = '/documents';
export const MEMBER_REWARDS = '/member-rewards';
export const ACCOUNT_DETAILS = '/account-details';
export const MEMBERS_RECOMMEND_A_FRIEND = '/members/recommend-a-friend';
export const FAQS = '/faqs';
export const FAQS_LOGGING_IN_TO_MYRAC = '/faqs/logging-in-to-myrac';
export const FAQS_MY_POLICY_DETAILS = '/faqs/my-policy-details';
export const FAQS_MY_PERSONAL_DETAILS = '/faqs/my-personal-details';
export const TERMS_CONDITIONS = '/terms-conditions';
export const UPDATE_PASSWORD = '/update-password';
export const FORGOT_PASSWORD = '/forgot-password';
export const BREAKDOWN_PRODUCTS = '/breakdown-products';
export const MY_COVER = '/my-cover';
export const VEHICLE_DETAILS = '/vehicle-details';
export const LOGOUT = '/logout';
export const SESSION_EXPIRED = '/expired-session';
export const LOGIN = '/login';
export const MYRAC = '/myrac';
export const TESCOLOGIN = '?referrer=tesco#/';
export const TESCOLOGINBREAKDOWNCOVER = '?referrer=tesco-breakdown-cover#/';
export const TESCOLOGINRENEWAL = '?referrer=tesco-renewal#/';
export const PAYMENT_PAGE = '/payment';
export const PAYMENT_ISSUE = '/payment-issue';
export const PRODUCT_UPGRADE_JOURNEY = '/breakdown-products/product-upgrade';
export const PRODUCT_UPGRADE_PAGE = '/breakdown-products/add-upgrade';
export const PRODUCT_UPGRADE_CONFIRMATION = '/breakdown-products/confirmation-page';
export const PRODUCT_UPGRADE_PAYMENT_CONFIRMATION = '/breakdown-products/payment-successful';
export const PRODUCT_UPGRADE_FAILED = '/breakdown-products/payment-failed';
export const RENEWAL = '/renewal';
export const RENEWAL_PAYMENT = '/renewal/payment';
export const RENEWAL_DIRECTDEBIT = '/renewal/directdebit';
export const RENEWAL_COMBINED_PAYMENTS = '/renewal/combined-payments';
export const RENEWAL_ERROR = '/renewal/error';
export const RENEWAL_CONFIRMATION = '/renewal/confirmation';
export const ORANGE_POINTS_JOURNEY = '/claim-points';

/**
 * Please list the pages that require authentication to view here. The PRIVATE_ROUTE_LIST array is used to recognise private pages for userJourney context.
 * */
export const PRIVATE_ROUTE_LIST = [
  ROOT,
  PERSONAL_DETAILS,
  DOCUMENTS,
  MEMBER_REWARDS,
  ACCOUNT_DETAILS,
  MEMBERS_RECOMMEND_A_FRIEND,
  UPDATE_PASSWORD,
  BREAKDOWN_PRODUCTS,
  MY_COVER,
  PAYMENT_ISSUE,
  VEHICLE_DETAILS,
  PRODUCT_UPGRADE_JOURNEY,
  PRODUCT_UPGRADE_PAGE,
  RENEWAL,
  ORANGE_POINTS_JOURNEY,
];

/**
 * Please list the pages fetch data on first render here. The API_ROUTE_LIST array is used to recognise pages that make requests for data on initialisation.
 * */
export const API_ROUTE_LIST = [
  ROOT,
  PERSONAL_DETAILS,
  DOCUMENTS,
  BREAKDOWN_PRODUCTS,
  MY_COVER,
  MEMBER_REWARDS,
  ACCOUNT_DETAILS,
  VEHICLE_DETAILS,
  PRODUCT_UPGRADE_JOURNEY,
  PRODUCT_UPGRADE_PAGE,
  RENEWAL,
  FAQS,
  TERMS_CONDITIONS,
];
