import React, { useState } from 'react';
import styles from './OrangePointsOptions.module.scss';

const Option = ({
  code,
  title,
  subTitle,
  description,
  chosenOption,
  isExtra,
  setChosenOption,
}) => {
  const isChosen = code === chosenOption;
  let classList = styles.option;

  if (isExtra) {
    classList += ` ${styles.isExtra}`;
  }
  if (isChosen) {
    classList += ` ${styles.isChosen}`;
  }
  if (!isChosen && chosenOption !== null) {
    classList += ` ${styles.greyedOut}`;
  }

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setChosenOption(code);
        }}
        className={classList}
      >
        {isExtra && <span className={styles.extraLabel}>{isExtra}</span>}
        <h3>
          <span className={styles.title}>{ title }</span>
          <span className={styles.subTitle}>{ subTitle }</span>
        </h3>
        <p className={styles.description}>
          { description }
        </p>
      </button>
      {isChosen && (
        <div className={styles.spendPointsFloatingBar}>
          <p>
            By spending your points you&apos;re confirming you&apos;ve read the reward T&Cs,
            and the reward you&apos;ve selected is right for you.
          </p>
          <button type="button">Spend your points</button>
        </div>
      )}
    </>
  );
};

const OrangePointsOptions = ({ smrDiscountOfferAmount, renewalDiscountOfferAmount }) => {
  const [chosenOption, setChosenOption] = useState(null);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          Here&apos;s what your points can get you...
        </div>
        <div className={styles.options}>
          <Option
            code="smrDiscountOffer"
            title={`Save £${smrDiscountOfferAmount}`}
            subTitle="on a service at home"
            description={`Points go further with RAC Mobile Mechanics. Get £${smrDiscountOfferAmount} off a full or interim service at home.`}
            isExtra="Extra saving"
            chosenOption={chosenOption}
            setChosenOption={setChosenOption}
          />
          <Option
            code="renewalDiscountOffer"
            title={`Save £${renewalDiscountOfferAmount}`}
            subTitle="on breakdown cover"
            description={`Get £${renewalDiscountOfferAmount} off your annual renewal price, spread across your next 12 payments.`}
            chosenOption={chosenOption}
            setChosenOption={setChosenOption}
          />
        </div>
      </div>
      <div className={styles.btnWrapper}>
        <p>
          By spending your points you&apos;re confirming you&apos;ve read the reward T&Cs, and the reward you&apos;ve selected is right for you.
        </p>
        <button disabled={chosenOption ? false : 'disabled'} className={styles.spendPointsBtn} type="button">Spend your points</button>
      </div>
    </>
  );
};

export default OrangePointsOptions;
