import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useRenewalHandler from 'hooks/renewal/useRenewalHandler';
import useRenewalPriceCalculator from 'hooks/renewal/useRenewalPriceCalculator';
import TermsAndConditions from 'views/MyRac/Renewal/Contents/TermsAndConditions';
import { SourceCodes } from 'constants/enums';
import Button from 'shared/components/Button/Button';
import PopupModal from 'components/PopupModal/PopupModal';
import infoIconSvg from 'assets/images/button/info-blue.svg';
import styles from './Basket.module.scss';

const Basket = () => {
  const { discountRenewal } = useSelector((state) => state.session);
  const pageModel = useSelector((state) => state.renewal.pageModel);
  const policyInfo = useSelector((state) => state.policy.policyInfo);
  const toggles = useSelector((state) => state.renewal.basket.toggles);
  const [showModal, setShowModal] = useState(false);

  const enableAutoRenew = pageModel?.autoRenewToggle?.isEnabled ? toggles.autoRenew : null;
  const enablePaperless = pageModel?.goPaperlessToggle?.isEnabled ? toggles.paperless : null;

  const { totalPrice, totalDiscountedPrice } = useRenewalPriceCalculator();
  const { renewalHandler } = useRenewalHandler(enableAutoRenew, enablePaperless, totalPrice, totalDiscountedPrice);

  return (
    <div className={styles.container}>
      <div className={`${styles.header} ${pageModel?.isMonthlyLongerTermPolicy ? styles.headerWithBorder : ''}`}>
        {discountRenewal?.isDiscountedPrice ? (
          <p className={styles.border}>
            {pageModel?.isMonthlyLongerTermPolicy
              /**
               * first one is for monthly users - currently only DD can be monthly
               * and upgrades are exluded for it so we don't have to calculate it (totalPrice)
               * BE prepared fields for this option: monthlyHeadlinePriceNow and monthlyHeadlinePriceWas
               */
              ? (
                <>
                  <span className="fw-bold orange">
                    £{pageModel?.basketContent?.monthlyHeadlinePriceNow.toFixed(2)}<sub>/month</sub>
                  </span>&nbsp;
                  <span className={styles.monthlyHeadlinePriceWas}>
                    £{pageModel?.basketContent?.monthlyHeadlinePriceWas.toFixed(2)}<sub>/month</sub>
                  </span>
                </>
              )
              /**
               * second one is regular annual DD or card price with discount
               */
              : <><span className="fw-bold orange">£{totalPrice.toFixed(2)}</span> <s>£{totalDiscountedPrice.toFixed(2)}</s></>}
          </p>
        ) : (
          <p>
            {pageModel?.isMonthlyLongerTermPolicy
              // DD monthly price without discounts here
              ? `£${pageModel?.basketContent?.monthlyHeadlinePriceNow.toFixed(2)} / month`
              // Annual Card or DD price without discount
              : `£${totalPrice.toFixed(2)}`}
          </p>
        )}
        <i className="fas fa-shopping-cart fa-2x orange" />
      </div>
      {!pageModel?.isMonthlyLongerTermPolicy && discountRenewal?.isDiscountedPrice && (
        <p className={styles.discountApplied}>{discountRenewal?.basketDiscountText} discount applied</p>
      )}
      {pageModel?.isMonthlyLongerTermPolicy && (
        <p className={styles.instalmentCountInfo}>
          Spread the Annual cost of £{pageModel?.price} over {pageModel?.basketContent?.instalmentCount} months with Pay Monthly.
        </p>
      )}
      <div className={styles.termsAndConditions}>
        <TermsAndConditions />
        {policyInfo?.sourceCode === SourceCodes.RWAGG01 && (
          <div>
            <img src={infoIconSvg} alt="info" height="18" width="18" className={styles.icon} />
            <span
              onClick={() => setShowModal(true)}
              className={styles.findOutMoreLink}
              role="link"
              onKeyDown={() => setShowModal(true)}
              tabIndex={0}
            >
              &#163;40 excess per callout
            </span>
          </div>
        )}
      </div>
      {showModal && (
        <PopupModal
          resultTitle="Your policy excess"
          resultText="Your cover includes an excess which is payable each time you make a claim on your breakdown policy. If you'd like to discuss this, please call us on 0330 159 1111."
          action={() => setShowModal(false)}
          displayDialogInMiddle
          innerCloseButton
        />
      )}
      <Button
        onClickHandler={(event) => renewalHandler(event)}
        buttonText="Renew now"
        chevronWhite
      />
    </div>
  );
};

export default Basket;
