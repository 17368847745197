import React, { useState } from 'react';
import { connect } from 'react-redux';
import { formatDate } from 'utils/date';
import infoIconSvg from '../../../../../assets/images/button/info-default.svg';
import PopupModal from '../../../../../components/PopupModal/PopupModal';
import coverDateLabels from '../../../../../utils/coverDateLabelsEnum';

export const CoverStatus = (props) => {
  const {
    policy: { policyInfo }, customer: { custInfo }, coverStatusText, status, statusAppend, statusTheme, description,
  } = props;

  const [showCoverModal, setShowCoverModal] = useState(false);

  // If text contains placeholder string for dates, replace the placeholder string with the appropriate policy date otherwise return the text if no placeholder strings exist
  const formatDescriptionText = (text) => {
    const coverDatesEnums = Object.values(coverDateLabels);

    const coverLabelText = coverDatesEnums.find((enums) => text.includes(enums));

    switch (coverLabelText) {
      case (coverDateLabels.renewal): return text.replace('{renewalDate}', formatDate(policyInfo.RenewalDate));
      case (coverDateLabels.start): return text.replace('{startDate}', formatDate(policyInfo.CoverStart));
      case (coverDateLabels.lapsed): return text.replace('{expiredDate}', formatDate(policyInfo.CoverEnd));
      case (coverDateLabels.cancelled): return text.replace('{cancellationDate}', formatDate(policyInfo.CancellationDate));
      case (coverDateLabels.email): return text.replace('{emailAddress}', custInfo?.Email);
      default:
        return text;
    }
  };

  const infoIcon = () => (
    <div
      tabIndex={0}
      role="button"
      onClick={() => setShowCoverModal(true)}
      onKeyDown={() => setShowCoverModal(true)}
      className="info-icon float-right"
    >
      <img src={infoIconSvg} alt="info" height="16" width="18" />
    </div>
  );

  const renderModal = () => (
    <PopupModal
      resultTitle={status}
      resultText={formatDescriptionText(description)}
      action={() => setShowCoverModal(false)}
      modalOk="OK"
      parseHtml
      innerCloseButton
      displayDialogAtTopQuarter
    />
  );

  return (
    <>
      <div className={`alert alert-${statusTheme} p-2 small`} role="alert">
        {coverStatusText}: <b>{status}</b> {statusAppend && `- ${statusAppend}`} {description && infoIcon()}
      </div>
      {showCoverModal && renderModal()}
    </>
  );
};

const mapStateToProps = (state) => ({
  policy: state.policy,
  customer: state.customer,
});

export default connect(mapStateToProps)(CoverStatus);
