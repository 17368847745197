import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dispatch } from 'store';
import { setRenewalPaymentPayload } from 'actions/renewalActions';
import { RENEWAL_PAYMENT, RENEWAL_DIRECTDEBIT, RENEWAL_COMBINED_PAYMENTS } from 'constants/routeConstants';
import { createUpgradeAmendment, createVehicleAmendment, createAdobeOfferPayload, selectedAdobeOfferStatus } from 'utils/renewalHelpers';

const useRenewalHandler = (enableAutoRenew, enablePaperless, totalPrice, totalDiscountedPrice) => {
  const { customerId, memberNo } = useSelector((state) => state.session);
  const navigate = useNavigate();

  const pageModel = useSelector((state) => state.renewal.pageModel);
  const selectedUpgrades = useSelector((state) => state.renewal.basket.upgrades);
  const selectedFLR = useSelector((state) => state.renewal.basket.freeLoyaltyReward);
  const policyInfo = useSelector((state) => state.policy.policyInfo);
  const vehiclesAdded = useSelector((state) => state.renewal.basket.vehicles);
  const chosenMonthlyFlex = useSelector((state) => state.renewal.basket.chosenMonthlyFlex);

  // Amendments created using helpers where json schema defined for each type
  const amendments = [];
  // Upgrades
  if (selectedUpgrades?.length > 0) {
    selectedUpgrades.map((upgrade) => upgrade && amendments.push(createUpgradeAmendment(upgrade)));
  }
  // Vehicles
  if (vehiclesAdded?.length > 0) {
    // Filter by only those edited and add each to amendments array for state
    vehiclesAdded?.filter((vehicle) => vehicle?.OldReg).map((vehicle) => vehicle && amendments.push(createVehicleAmendment(vehicle)));
  }

  // Adobe More4More offer selection for applicable users
  const adobeOffer = createAdobeOfferPayload(pageModel?.adobeOfferStatus, pageModel?.freeLoyaltyRewards, selectedFLR.propositionId);

  const renewalHandler = (event) => {
    event.preventDefault();

    dispatch(setRenewalPaymentPayload({
      CustomerId: customerId,
      MembershipNumber: memberNo,
      Price: totalPrice,
      PriceBeforeDiscount: totalDiscountedPrice,
      MonthlyLongerTermPolicy: pageModel?.isMonthlyLongerTermPolicy,
      RenewalRiskVersion: pageModel?.renewalRiskVersion,
      StateToken: pageModel?.stateToken,
      PolicyNumber: policyInfo?.PolicyNumber,
      ConfirmationPage: pageModel?.confirmationPage,
      AdobeOfferStatus: selectedAdobeOfferStatus(pageModel?.adobeOfferStatus, selectedFLR.propositionId),
      PropositionId: adobeOffer?.propositionId ?? null,
      AdobeCode: adobeOffer?.adobeCode ?? null,
      AdobeOfferTitle: adobeOffer?.title ?? null,
      PaymentDate: pageModel?.directDebitPaymentDate,
      DirectDebitPage: pageModel?.directDebitPage,
      EnableAutoRenew: enableAutoRenew,
      EnablePaperless: enablePaperless,
      Amendments: amendments,
      MemberChoseSwitchToMonthlyFlex: chosenMonthlyFlex,
    }));

    if (chosenMonthlyFlex) {
      navigate(RENEWAL_COMBINED_PAYMENTS);
    } else if (pageModel?.paymentMethod === 'DirectDebit') {
      navigate(RENEWAL_DIRECTDEBIT);
    } else {
      navigate(RENEWAL_PAYMENT);
    }
  };

  return { renewalHandler };
};

export default useRenewalHandler;
