import React from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import styles from './PaymentSummary.module.scss';

const PaymentSummary = ({ monthlyFlexSummary = false }) => {
  const pageModel = useSelector((state) => state.renewal.pageModel);

  return (
    <div className={monthlyFlexSummary ? `${styles.container} ${styles.monthlyFlexSummary}` : styles.container}>
      <h3>Payment summary</h3>
      <ul>
        {pageModel?.newCoverStartDate && (
          <li>Cover starts on: <span>{format(new Date(pageModel?.newCoverStartDate), 'd MMM yyyy')}</span></li>
        )}
        {pageModel?.basketContent?.firstInstalmentDate && (
          <li>Your Direct Debit starts on: <span>{format(new Date(pageModel?.basketContent?.firstInstalmentDate), 'd MMM yyyy')}</span></li>
        )}
        {!monthlyFlexSummary && (
          <>
            <li>First instalment: <span>£{pageModel?.basketContent?.firstInstalmentPrice.toFixed(2)}</span></li>
            <li>Remaining instalments: <span>£{pageModel?.basketContent?.remainingInstalmentPrice.toFixed(2)}</span></li>
            <li>Total cost: <span>£{pageModel?.price.toFixed(2)}</span></li>
          </>
        )}
      </ul>
    </div>
  );
};

export default PaymentSummary;
