import React, { useEffect } from 'react';
import { loggerFunc } from 'utils/logger';
import { useNavigate, useLocation } from 'react-router-dom';
import { LOGIN } from 'constants/routeConstants';
import Button from '../../shared/components/Button/Button';
import styles from './Logout.module.scss';
import { logoutUser } from '../../auth/userManager';

const logger = loggerFunc('Session Expired');

const SessionExpired = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const toggleShowModal = () => {
    logoutUser(
      () => {
        logger.debug('Logged out');
        navigate(LOGIN);
      },
      (e) => {
        logger.error('Error occured while logging out...', e);
      },
    );
  };

  useEffect(() => {
    if (state?.logoutAfterNavigate) {
      logoutUser(
        () => {
          logger.debug('Logged out');
        },
        (e) => {
          logger.error('Error occured while logging out...', e);
        },
      );
    }
  }, []);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Your session has expired...</h1>
      <p className={styles.text}>To access MyRAC please login again.</p>
      <div className={styles.buttonContainer}>
        <Button buttonStyles={{ width: '200px', backgroundColor: '#4a4a4a' }} onClickHandler={toggleShowModal} buttonText="Login" />
      </div>

    </div>
  );
};

export default SessionExpired;
