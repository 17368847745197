import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loggerFunc } from 'utils/logger';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import * as RouteConstants from 'constants/routeConstants';
import VehicleDetailsMain from 'components/VehicleRemindersPage/Reminders/VehicleRemindersPage';
// LoggedOut routes
import FAQsFrequentQuestions from 'views/FAQ/FrequentQuestions';
import FAQLoggingIn from 'views/FAQ/LoggingIn';
import FAQPolicyDetails from 'views/FAQ/MyMembership';
import FAQPersonalDetails from 'views/FAQ/PersonalDetails';
import RegistrationLogin from 'views/RegistrationLogin/RegistrationLogin';
import Logout from 'views/RegistrationLogin/Logout';
import SessionExpired from '../RegistrationLogin/SessionExpired';
import PageNotFound from './PageNotFound';
import './myRac.scss';
// LoggedIn routes
import { useApplicationState } from '../../hooks/applicationState-Context';
import { populateMessages } from '../../services/MessageService';
import LoggedInView from '../../layout/loggedInView/LoggedInView';
import RequireAuth from '../../RequireAuth';
import MainContent from '../../layout/mainContent/MainContent';
import AccountSettings from './AccountSettings/AccountSettings';
import MyCover from './MyCover/MyCover';
import MembershipOverview from './MembershipOverview/MembershipOverview';
import MembershipDocuments from './MembershipDocuments';
import MemberBenefits from './MemberBenefits';
import PersonalDetails from './PersonalDetails';
import UpdatePassword from './UpdatePassword';
import TermsAndConditions from './TermsAndConditionsPage';
// Product Upgrade
import { useUserJourney } from '../../hooks/userJourney-Context';
import { TACTICAL_UPGRADE_JOURNEY } from '../../constants/journeyManagerStepConstants';
import JourneyManager from './ProductUpgrade/JourneyManager/JourneyManager';
import ProductUpgradeJourney from './ProductUpgrade/ProductUpgradeJourney';
import PaymentPage from './ProductUpgrade/PaymentPage/PaymentPage';
import UpgradeConfirmationPage from './ProductUpgrade/UpgradeConfirmationPage';
import PaymentFailedPage from './ProductUpgrade/PaymentFailedPage';
import PaymentIssue from './PaymentIssue';
// Renewal
import RenewalsView from '../../layout/renewalsView/RenewalsView';
import Renewal from './Renewal/Renewal';
import PaymentScreen from '../../components/PaymentService/PaymentScreen';
import DirectDebitScreen from '../../components/DirectDebit/DirectDebitScreen';
import CombinedPaymentsScreen from '../../components/CombinedPayments/CombinedPaymentsScreen';
import RenewalErrorPage from './Renewal/RenewalErrorPage/RenewalErrorPage';
import RenewalConfirmationPage from './Renewal/RenewalConfirmationPage/RenewalConfirmationPage';
// Orange points
import OrangePointsView from '../../layout/orangePointsView/OrangePointsView';
import OrangePointsRewardsScreen from './OrangePoints/OrangePointsRewardsScreen';

const MyRac = (props) => {
  const { userJourneyState, storePreviousUrlPath, storeCurrentUrlPath } = useUserJourney();
  const { setPageLoading } = useApplicationState();
  const { featureFlags } = props;

  const location = useLocation();

  const logger = loggerFunc('MyRAC');

  useEffect(() => {
    async function reloadMessages() {
      try {
        await populateMessages();
        logger.debug('Messages loaded.');
      } catch (e) {
        logger.error(e);
      }
    }
    reloadMessages();
  }, []);

  useEffect(() => {
    storeCurrentUrlPath(location.pathname);
    storePreviousUrlPath(location.pathname);
  }, [location]);

  useEffect(() => {
    // If location is in API ROUTE LIST initiate page loading spinner which will be set to false once that page's API request has complete. Also checks to see if the page the customer is trying to navigate to is the same as the current page.
    if (RouteConstants.API_ROUTE_LIST.includes(location.pathname) && location.pathname !== userJourneyState.currentUrl) {
      setPageLoading(true);
    } else {
      setPageLoading(false);
    }
  }, [location]);

  return (
    <div>
      <Routes basename={RouteConstants.MYRAC}>
        <Route path={RouteConstants.ROOT} element={<MainContent />}>
          <Route index path={RouteConstants.LOGIN} element={<RegistrationLogin />} />
          <Route path={RouteConstants.ROOT} element={<LoggedInView />}>
            <Route path={RouteConstants.ROOT} element={(<RequireAuth><MembershipOverview /></RequireAuth>)} />
            <Route path={RouteConstants.MY_COVER} element={(<RequireAuth><MyCover /></RequireAuth>)} />
            <Route path={RouteConstants.BREAKDOWN_PRODUCTS} element={<Navigate replace to={RouteConstants.MY_COVER} />} />
            <Route path={RouteConstants.VEHICLE_DETAILS} element={(<RequireAuth><VehicleDetailsMain /></RequireAuth>)} />
            <Route path={RouteConstants.PRODUCT_UPGRADE_JOURNEY} element={(<RequireAuth><JourneyManager journeyName={TACTICAL_UPGRADE_JOURNEY} journeySteps={[ProductUpgradeJourney]} /></RequireAuth>)} />
            <Route path={RouteConstants.PRODUCT_UPGRADE_PAGE} element={(<RequireAuth><JourneyManager journeyName={TACTICAL_UPGRADE_JOURNEY} journeySteps={[ProductUpgradeJourney, PaymentPage]} /></RequireAuth>)} />
            <Route path={RouteConstants.PAYMENT_PAGE} element={(<RequireAuth><PaymentPage /></RequireAuth>)} />
            <Route path={RouteConstants.PAYMENT_ISSUE} element={(<RequireAuth><PaymentIssue /></RequireAuth>)} />
            <Route path={RouteConstants.PRODUCT_UPGRADE_CONFIRMATION} element={<RequireAuth><UpgradeConfirmationPage /></RequireAuth>} />
            <Route path={RouteConstants.PRODUCT_UPGRADE_PAYMENT_CONFIRMATION} element={<RequireAuth><UpgradeConfirmationPage isPaymentJourney /></RequireAuth>} />
            <Route path={RouteConstants.PRODUCT_UPGRADE_FAILED} element={<RequireAuth><PaymentFailedPage /></RequireAuth>} />
            <Route path={RouteConstants.DOCUMENTS} element={(<RequireAuth><MembershipDocuments /></RequireAuth>)} />
            <Route path={RouteConstants.MEMBER_REWARDS} element={(<RequireAuth><MemberBenefits /></RequireAuth>)} />
            <Route path={RouteConstants.ACCOUNT_DETAILS} element={(<RequireAuth><AccountSettings /></RequireAuth>)} />
            <Route path={RouteConstants.PERSONAL_DETAILS} element={(<RequireAuth><PersonalDetails /></RequireAuth>)} />
            <Route path={RouteConstants.MEMBERS_RECOMMEND_A_FRIEND} element={(<RequireAuth><MembershipOverview /></RequireAuth>)} />
            <Route path={RouteConstants.UPDATE_PASSWORD} element={(<RequireAuth><UpdatePassword /></RequireAuth>)} />
            <Route path={RouteConstants.FAQS} element={<FAQsFrequentQuestions />} />
            <Route path={RouteConstants.FAQS_LOGGING_IN_TO_MYRAC} element={<FAQLoggingIn />} />
            <Route path={RouteConstants.FAQS_MY_POLICY_DETAILS} element={<FAQPolicyDetails />} />
            <Route path={RouteConstants.FAQS_MY_PERSONAL_DETAILS} element={<FAQPersonalDetails />} />
            <Route path={RouteConstants.TERMS_CONDITIONS} element={<TermsAndConditions />} />
            <Route path={RouteConstants.FORGOT_PASSWORD} element={<UpdatePassword />} />
            <Route path={RouteConstants.LOGOUT} element={<Logout />} />
            <Route path={RouteConstants.SESSION_EXPIRED} element={<SessionExpired />} />
            <Route path={RouteConstants.TESCOLOGIN} element={<Navigate replace to={RouteConstants.ROOT} />} />
            <Route path={RouteConstants.TESCOLOGINBREAKDOWNCOVER} element={<Navigate replace to={RouteConstants.ROOT} />} />
            <Route path={RouteConstants.TESCOLOGINRENEWAL} element={<Navigate replace to={RouteConstants.ROOT} />} />
            <Route path={RouteConstants.RENEWAL} element={featureFlags?.isLDReady && featureFlags?.allowRenewalJourney !== 1 && (<Navigate replace to={RouteConstants.ROOT} />)} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>
        <Route path={RouteConstants.RENEWAL} element={featureFlags?.isLDReady && featureFlags?.allowRenewalJourney === 1 && <RequireAuth><RenewalsView /></RequireAuth>}>
          <Route index element={<Renewal />} />
          <Route path={RouteConstants.RENEWAL_PAYMENT} element={<RequireAuth><PaymentScreen route={RouteConstants.RENEWAL} /></RequireAuth>} />
          <Route path={RouteConstants.RENEWAL_DIRECTDEBIT} element={<RequireAuth><DirectDebitScreen route={RouteConstants.RENEWAL} /></RequireAuth>} />
          <Route path={RouteConstants.RENEWAL_COMBINED_PAYMENTS} element={<RequireAuth><CombinedPaymentsScreen route={RouteConstants.RENEWAL} /></RequireAuth>} />
          <Route path={RouteConstants.RENEWAL_ERROR} element={<RequireAuth><RenewalErrorPage /></RequireAuth>} />
          <Route path={RouteConstants.RENEWAL_CONFIRMATION} element={<RequireAuth><RenewalConfirmationPage /></RequireAuth>} />
        </Route>
        <Route path={RouteConstants.ORANGE_POINTS_JOURNEY} element={<RequireAuth><OrangePointsView /></RequireAuth>}>
          <Route index element={(<OrangePointsRewardsScreen />)} />
        </Route>
      </Routes>
    </div>
  );
};

const mapStateToProps = (state) => ({
  featureFlags: state.featureToggle,
});

export default connect(mapStateToProps)(MyRac);
