import React from 'react';
import logo from 'views/MyRac/Renewal/assets/logo.png';
import RenderLink from 'components/RenderLink';
import { ROOT } from 'constants/routeConstants';
import styles from './Logo.module.scss';

const Logo = () => (
  <nav className={styles.container}>
    <div className={styles.mainContainer}>
      <RenderLink url={ROOT}>
        <img className={styles.logo} src={logo} alt="RAC Logo" height="32px" />
      </RenderLink>
    </div>
  </nav>
);

export default Logo;
