import * as RouteConstants from '../../constants/routeConstants';

const supportedVerificationRoutes = [{
  path: RouteConstants.RENEWAL,
  trackingValue: 'MyRAC_RenewalFromEmailVerificatonRequired',
},
{
  path: RouteConstants.PRODUCT_UPGRADE_PAGE,
  trackingValue: 'MyRAC_UpgradeFromEmailVerificatonRequired',
}];

export default supportedVerificationRoutes;
